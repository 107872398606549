import React, { useEffect, useState, useMemo } from "react";
import debounce from "lodash.debounce";
import toNumber from "lodash/toNumber";
import { useDispatch, useSelector } from "react-redux";
import { actUserLichSuGiaoDich } from "../../redux/action/AuthManagerApi";
import { useJwt } from "../../hooks";
import { TOKEN } from "../../redux/settings/config";
import { useAccount } from "wagmi";
const useTrasaction = () => {
  const { address } = useAccount();
  const dispatch = useDispatch();
  const { transationHistory, authLogin }: any = useSelector<any>(
    (state) => state.auth
  );
  const { accountStatus } = useJwt();
  const [isPage, setIsPage] = useState({
    pageIndex: "0",
    pageSize: "10",
    type: -1,
  });
  const [isList, setIsList] = useState([]);
  const [total, setTotal] = useState<any>(0);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [checkLoading, setCheckLoading] = useState(false);

  useMemo(() => {
    if (transationHistory !== null) {
      setIsList(transationHistory.Data.Item);
      setTotal(transationHistory.Data.Total);
      setTotalPage(Math.floor(total / toNumber(isPage.pageSize)));
      setCheckLoading(false);
      return;
    }
  }, [transationHistory]);

  useEffect(() => {
    if (!checkLoading && !address) {
      setIsList([]);
      setCheckLoading(false);
      return;
    }
  }, [checkLoading, address]);

  const callApiDelay = (page: any) => {
    setCheckLoading(true);
    dispatch(debounce(actUserLichSuGiaoDich(page), 800));
  };

  useEffect(() => {
    if ((address || address) && localStorage.getItem(TOKEN)!!) {
      callApiDelay(isPage);
      setCheckLoading(true);
      return;
    }
  }, [address, address, accountStatus, isPage, authLogin]);

  return {
    setIsPage,
    isPage,
    isList,
    total,
    totalPage,
    checkLoading,
  };
};

export default useTrasaction;
