import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  bsc,
  injected,
  walletconnect1,
} from "../utils/connector";
import { useAccount } from "wagmi";

export default function useConnectWallet() {
  const { connector, account, activate, deactivate, active }: any =
    useWeb3React();
  const {address, connector: connectCW} = useAccount();
  const [currentConnector, setCurrentConnector]: any = useState();
  const [currentConnectorId, setCurrentConnectorId] = useState();

  useEffect(() => {
    // if (
    //   currentConnectorId &&
    //   currentConnector &&
    //   currentConnector === (connector || connectCW)
    // ) {
    //   // Activated
    //   if (account || address) {
    //     localStorage.setItem("accountStatus", "1");
    //     localStorage.setItem("connectorId", currentConnectorId);
    //     // if (window.ethereum && window.ethereum.isMetaMask) {
    //     //   window.ethereum.request({ method: "wallet_switchEthereumChain" });
    //     // }
    //     if (window.ethereum && window.ethereum.isMetaMask) {
    //       window?.ethereum?.request({
    //         method: "wallet_addEthereumChain",
    //         params: [
    //           {
    //             chainName: "BNB Chain Mainnet",
    //             chainId: "0x38",
    //             rpcUrls: ["https://bsc-dataseed.binance.org"],
    //             nativeCurrency: {
    //               name: "BNB",
    //               symbol: "BNB",
    //               decimals: 18,
    //             },
    //           },
    //         ],
    //       });
    //     }
    //   }
    // }
  }, [account, currentConnectorId, currentConnector, connector, address, connectCW]);

  async function walletLogin(connectorId: any) {
    let _connector;

    switch (connectorId) {
      // case "walletconnect":
      //   _connector = walletconnect1;
      //   break;
      // case "bsc":
      //   _connector = bsc;
      //   break;
      default:
        // injected
        _connector = injected || connectCW;
        break;
    }
    setCurrentConnectorId(connectorId);
    setCurrentConnector(_connector);
    !active && (await activate(_connector));
  }

  function walletLogout() {
    deactivate();
    window.localStorage.removeItem("accountStatus");
    window.localStorage.removeItem("connectorId");
    window.localStorage.removeItem("walletconnect");
  }

  return { walletLogin, walletLogout };
}
