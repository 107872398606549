import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: SFRegular;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #141718;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #CB57C8;
    }
  }
  body {
    overflow-x: hidden;
    width: 100%;
    background: #141718;
    color: #fff;
    img {
      height: auto;
      max-width: 100%;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #141718;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #CB57C8;
    }
  }
  iframe {
    display: none;
  }

  li {
      list-style-type: none
    }
`;

export default GlobalStyle;
