import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 13 24" fill="#fff" {...props}>
      <svg
        width="13"
        height="24"
        viewBox="0 0 13 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.600144 12C0.600144 11.0667 0.960143 10.1333 1.66681 9.42666L10.3601 0.733329C10.7468 0.346662 11.3868 0.346662 11.7735 0.733329C12.1601 1.12 12.1601 1.76 11.7735 2.14666L3.08014 10.84C2.44014 11.48 2.44014 12.52 3.08014 13.16L11.7735 21.8533C12.1601 22.24 12.1601 22.88 11.7735 23.2667C11.3868 23.6533 10.7468 23.6533 10.3601 23.2667L1.66681 14.5733C0.960143 13.8667 0.600144 12.9333 0.600144 12Z"
          fill="#F2F2F2"
        />
      </svg>
    </Svg>
  );
};

export default Icon;
