import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 100%;
  max-width: 1100px;
  min-height: 64vh;
  margin: auto;
  padding: 0px 10px;
  :before {
    position: absolute;
    content: "";
    top: 84px;
    left: 0px;
    width: 100%;
    height: 20.833333333vw;
    max-height: 300px;
    background: linear-gradient(rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%);
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: -1;
  }
  @media only screen and (max-width: 1240px) {
    padding: 0px 10px;
    padding-top: 20px;
  }
  @media only screen and (max-width: 767px) {
    :before {
      top: 64px;
    }
  }
`;
export const Title = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin-bottom: 16px;
    font-size: 18px;
    font-family: PlayBold;
  }
  @media only screen and (max-width: 743px) {
    h1 {
      font-size: 6.25vw;
      margin-bottom: 6.25vw;
    }
  }
`;

export const FlexBoxPagi = styled(Flex)`
  position: relative;
  justify-content: flex-end;
  .pagination {
    display: flex;
  }
  .page-item {
    width: 33px;
    height: 33px;
    transition: all 0.25s;
    border: 1px solid transparent;
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 5px;
      transition: all 0.25s;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.active {
      border-radius: 4px;
      border: 1px solid #5c5c5c;
      background: rgba(255, 255, 255, 0.1);
    }
    &:hover {
      border-radius: 4px;
      border: 1px solid #5c5c5c;
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media only screen and (max-width: 743px) {
    .page-item {
      width: 6vw;
      height: 6vw;
      font-size: 5vw;
      .page-link {
        width: 6vw;
        height: 6vw;
      }
      &:not(:last-child) {
        margin-right: 5vw;
      }
    }
  }
  .ant-table-footer {
    .ant-row {
      flex-wrap: wrap;
    }
  }
`;
