import dashboard from '../../../assets/dashboard/dashborad.svg'
import home from '../../../assets/dashboard/home.svg'
import invest from '../../../assets/dashboard/invest.svg'
import network from '../../../assets/dashboard/network.svg'
import swap from '../../../assets/dashboard/swap.svg'
import transaction from '../../../assets/dashboard/transaction.svg'
import withdraw from '../../../assets/dashboard/withdraw.svg'

export const ArrClone = [
  {
    title: "Home",
    href: "/",
    img: home
  },
  {
    title: "Dashboard",
    href: "/dashboard",
    img: dashboard
  },
  {
    title: "Invest",
    href: "/invest",
    img: invest
  },
  // {
  //   title: "Swap",
  //   href: "/swap",
  //   img: swap
  // },
  {
    title: "Transaction",
    href: "/transaction",
    img: transaction
  },
  {
    title: "Network",
    href: "/network",
    img: network
  },
  {
    title: "Withdraw",
    href: "/withdraw",
    img: withdraw
  },

  // {
  //   title: "Docs",
  //   href: "/docs",
  // },
];
