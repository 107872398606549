import styled from "styled-components";
import { BoxCommon } from "../../style/styles";

export const FormProfileContainer = styled.div`
  width: 495px;
  @media screen and (max-width: 743px) {
    width: auto;
    border-radius: 24px;
    overflow: hidden;
    ${BoxCommon} {
      max-height: 550px;
      overflow-y: auto;
      border-radius: 24px;
    }
  }
`;
export const ProfileTitle = styled.p`
  text-align: center;
  color: #fcfcfd;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;
`;
export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  p {
    margin-bottom: 5px;
    color: #fcfcfd;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
  span {
    color: #98a2b3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const ProfileInputs = styled.div`
  margin-bottom: 30px;
`;
export const InputRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  & > p:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const ProfileButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
