import React, { lazy, Suspense, useContext, useEffect } from "react";
import {
  Switch,
  // Router,
  // useLocation,
  Route,
  useLocation,
  // BrowserRouter,
} from "react-router-dom";
import { ResetCSS, Box } from "@raca2022/uikit";
import AOS from "aos";
// import { ScrollToTop } from "../components/ScrollTop";
import { Menu } from "../components/Menu";
// import Footer from "../components/FooterAirPayOne";
// import Loading from "../components/Loading/LoadingDesktop";

// import history from "../routerHistory";
import GlobalStyle from "../style";
import "aos/dist/aos.css";
// import { TOKEN } from "../redux/settings/config";
import { Toaster } from "react-hot-toast";
// import { LoginProvider } from "../components/Provider/LoginProvider";
// import Connect from "../components/Menu/ButtonConnect";
import HeaderDashborad from "../components/Header";
import styled from "styled-components";
import line_bg from "../assets/swap/line_bg.png";
import line_bg_sp from "../assets/swap/line_bg_sp.png";
import Network from "./Aleo/Network";
import {
  ModalConfirmContext,
  // ModalConfirmProvider,
} from "../components/ProviderPopUp/confirm";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { actGetLisDasboard } from "../redux/action/AuthManagerApi";
import { useJwt } from "../hooks";
import { TOKEN } from "../redux/settings/config";
import { useAccount } from "wagmi";
import { ToastContainer } from "react-toastify";

//
const Home = lazy(() => import("./Home"));
const Dashboard = lazy(() => import("./Aleo/Dashboard"));
const Invest = lazy(() => import("./Aleo/Invest"));
const Swap = lazy(() => import("./Aleo/Swap"));
const Withdraw = lazy(() => import("./Aleo/Withdraw"));
const Transaction = lazy(() => import("./Aleo/Transaction"));

const App = () => {
  const { collapse } = useContext(ModalConfirmContext)!;
  useEffect(() => {
    AOS.init();
    // return localStorage.removeItem(TOKEN);
  }, []);

  const dispatch = useDispatch();
  const { address } = useAccount();
  const { accountStatus } = useJwt();

  const { authLogin }: any = useSelector<any>((state) => {
    return state.auth;
  });

  const callApiDelay = () => {
    dispatch(debounce(actGetLisDasboard(), 800));
  };

  useEffect(() => {
    if ((address) && localStorage.getItem(TOKEN)!!) {
      callApiDelay();
    }
  }, [ address, accountStatus, authLogin]);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          error: {
            duration: 1500,
          },
        }}
      />
      <ResetCSS />
      <GlobalStyle />
      <Box className="height-content overFlow cus-pt-global" overflow="hidden">
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <DashboardContainer>
            <Menu />
            <DashboardContent>
              <HeaderDashborad />
              <ContentBody collapse={collapse}>
                <BodyBg>
                  <Route path="/dashboard" exact>
                    <Dashboard />
                  </Route>
                  <Route path="/invest" exact>
                    <Invest />
                  </Route>
                  {/* <Route path="/swap" exact>
                    <BgLine>
                      <Swap />
                    </BgLine>
                  </Route> */}
                  <Route path="/withdraw" exact>
                    <BgLine>
                      <Withdraw />
                    </BgLine>
                  </Route>
                  <Route path="/transaction" exact>
                    <Transaction />
                  </Route>
                  <Route path="/network" exact>
                    <Network />
                  </Route>
                </BodyBg>
              </ContentBody>
            </DashboardContent>
            <ToastContainer/>
          </DashboardContainer>
        </Switch>
      </Box>
    </>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  padding-top: 100px;
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    padding-top: 95px;
  }
  @media screen and (max-width: 743px) {
    padding-top: 21vw;
  }
`;
const DashboardContent = styled.div`
  width: 100%;
`;
const ContentBody = styled.div<{ collapse?: boolean }>`
  overflow-y: auto;
  width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 295px)"};
  margin-left: auto;
  padding-top: 100px;
  padding: 20px;
  transition: width 0.25s;
  @media screen and (max-width: 1080px) {
    width: 100%;
    padding-bottom: 50px;
  }
  /* @media screen and (min-width: 1081px) and (max-width: 1350px) {
    width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 340px)"};
  } */
  @media screen and (max-width: 743px) {
    padding: 0;
  }
`;
const BodyBg = styled.div`
  position: relative;
  padding: 32px 28px;
  border-radius: 20px;
  background: #232627;
  min-height: calc(100vh - 148px);
  overflow: hidden;
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    padding: 20px;
  }
  @media screen and (max-width: 743px) {
    padding: 4vw 5.3vw;
    border-radius: 0;
    min-height: calc(100vh - 18vw);
  }
`;
const BgLine = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: url(${line_bg}) no-repeat center / cover;
    z-index: 0;
    height: 100%;
  }
  @media screen and (max-width: 743px) {
    &::before {
      min-height: 100%;
      background-image: url(${line_bg_sp});
    }
  }
`;

export default App;
