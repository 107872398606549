import { useContext } from "react";
import { BoxCommon, ButtonCloseModal, ButtonCommon } from "../../style/styles";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { CloseConnectIcon } from "../Svg";
import {
  FormProfileContainer,
  InputRow,
  ProfileButtons,
  ProfileInfo,
  ProfileInputs,
  ProfileTitle,
} from "./styled";
import avatar from "../../assets/formProfile/avatar.png";
import { BoxInput } from "../../view/Swap/styled";

const FormProfile = () => {
  const { onClose } = useContext(ModalConfirmContext)!;
  return (
    <FormProfileContainer>
      <BoxCommon icon={true}>
        <ButtonCloseModal onClick={onClose}>
          <CloseConnectIcon />
        </ButtonCloseModal>
        <ProfileTitle>Update Profile</ProfileTitle>
        <ProfileInfo>
          <figure>
            <img src={avatar} alt="avatar" />
          </figure>
          <p>Username</p>
          <span>email@gmail.com</span>
        </ProfileInfo>
        <ProfileInputs>
          <InputRow>
            <p>Username</p>
            <BoxInput type="text" placeholder="Input Username" />
          </InputRow>
          <InputRow>
            <p>Phone Number</p>
            <BoxInput type="text" placeholder="Input Phone Number" />
          </InputRow>
          <InputRow>
            <p>Email</p>
            <BoxInput type="text" placeholder="Input Email" />
          </InputRow>
        </ProfileInputs>
        <ProfileButtons>
          <ButtonCommon onClick={onClose} transparent>Cancel</ButtonCommon>
          <ButtonCommon>Save Changes</ButtonCommon>
        </ProfileButtons>
      </BoxCommon>
    </FormProfileContainer>
  );
};

export default FormProfile;
