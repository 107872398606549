import React, { useState, useContext, memo, useEffect } from "react";

const MenuContext = React.createContext(null);

const MenuProvider = memo(({ children }:any) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarOpen]);

  return (
    <MenuContext.Provider
      // @ts-ignore
      value={{
        isSidebarOpen,
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
});

export const useMenuContext = () => {
  return useContext(MenuContext);
};

export { MenuContext, MenuProvider };
