import styled from "styled-components";
import bg from "../../../assets/invest/bg.png";
import left_hand from "../../../assets/invest/left_hand.png";
import right_hand from "../../../assets/invest/right_hand.png";
import left_hand_sp from "../../../assets/invest/left_hand_sp.png";
import right_hand_sp from "../../../assets/invest/right_hand_sp.png";

export const InvestContainer = styled.div`
  overflow: hidden;
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs {
    .ant-tabs-tab {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        span {
          color: #101828;
          background-color: #f9f5ff;
        }
      }
    }
    .ant-tabs-tab-btn {
      p {
        font-size: 17px;
      }
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        font-family: Manrope;
        font-size: 14px;
        font-weight: bold;
        border-radius: 50%;
        color: #98a2b3;
        background-color: #1d2939;
      }
    }
  }
  @media screen and (max-width: 743px) {
    .ant-tabs {
      .ant-tabs-tab-btn {
        span {
          width: 5.3vw;
          height: 5.3vw;
          font-size: 3.2vw;
        }
      }
    }
  }
`;
export const InvestKeyvisual = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 310px;
  border-radius: 16px;
  padding: 65px;
  margin-bottom: 30px;
  /* background: #341455; */
  @media screen and (min-width: 744px) and (max-width: 1550px) {
    padding: 30px;
  }
  @media screen and (max-width: 743px) {
    min-height: 44vw;
    padding: 4.2vw 4vw;
  }
`;
export const KeyvisualBg = styled.div`
  &::before {
    content: "";
    position: absolute;
    background: url(${bg}) no-repeat center / cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* mix-blend-mode: luminosity; */
    border-radius: 16px;
  }
`;
export const KeyvisualTitle = styled.div`
  color: #fff;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-shadow: 0px 0px 35px #b925ff;
  span {
    color: #b692f6;
  }
  @media screen and (min-width: 744px) and (max-width: 1550px) {
    font-size: 32px;
  }
  @media screen and (max-width: 743px) {
    font-size: 5.6vw;
    margin-bottom: 2vw;
  }
`;
export const KeyvisualContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    top: -50px;
    height: 307px;
    z-index: -1;
  }
  &::before {
    left: -50%;
    background-image: url(${right_hand});
    transform: rotateY(180deg);
    width: 495px;
  }
  &::after {
    right: -50%;
    background-image: url(${right_hand});
    width: 469px;
  }
  &.member {
    &::before,
    &::after {
      content: none
    }
    ${KeyvisualTitle} {
      font-size: 50px;
      span {
        display: block;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1023px) {
    &::before {
      left: -75%;
    }
    &::after {
      right: -75%;
    }
  }
  @media screen and (max-width: 743px) {
    &::before,
    &::after {
      height: 28.2vw;
      width: 28.2vw;
      top: 8.5vw;
    }
    &::before {
      /* background-image: url(${left_hand_sp}); */
      left: -5.6vw;
    }
    &::after {
      /* background-image: url(${right_hand_sp}); */
      right: -5.6vw;
    }
    &.member {
      ${KeyvisualTitle} {
        font-size: 8vw;
      }
    }
  }
`;
export const KeyvisualDiscount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #d0d5dd;
  @media screen and (max-width: 743px) {
    margin-bottom: 2.5vw;
    font-size: 3.2vw;
  }
`;
export const KeyvsiualPrice = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #32d583;
  @media screen and (max-width: 743px) {
    font-size: 7.4vw;
    margin-bottom: 2.6vw;
  }
`;
export const InvestPackages = styled.div``;
export const PackagesTitle = styled.div`
  display: flex;
  align-items: center;
  color: #fcfcfd;
  font-size: 24px;
  font-weight: 700;
  gap: 15px;
  margin-bottom: 20px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 44px;
    background-color: #98a2b3;
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
  }
  @media screen and (max-width: 743px) {
    font-size: 5.3vw;
    margin-bottom: 4vw;
    gap: 4vw;
    span {
      font-size: 3.7vw;
      padding: 2.4vw 3.2vw;
      border-radius: 50%;
    }
  }
`;
export const TabsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 2.6vw;
  }
`;
