import React, { useContext, useState } from "react";
import { Flex, Text } from "@raca2022/uikit";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { ArrClone } from "./config";
import { ModalConfirmContext } from "../../ProviderPopUp/confirm";

const MenuList = () => {
  const [isArr] = useState(ArrClone);
  const { pathname } = useLocation();
  const { onCloseMenu, collapse } = useContext(ModalConfirmContext)!;
  return (
    <WrapperList collapse={collapse}>
      {isArr.map((_item, _index) => {
        return (
          <React.Fragment key={_index}>
            {_item.title === "DOCS" ? (
              <a href={_item.href} target="_blank">
                <Flex>
                  <Item as="h1">{_item.title}</Item>
                </Flex>
              </a>
            ) : (
              <LinkItem
                onClick={(e) => {
                  onCloseMenu();
                  e.stopPropagation();
                }}
                className={pathname === _item.href ? "active" : ""}
              >
                <NavLink to={_item.href}>
                  <LinkInner>
                    <img src={_item.img} alt="img" />
                    <Item as="h1">{_item.title}</Item>
                  </LinkInner>
                </NavLink>
              </LinkItem>
            )}
          </React.Fragment>
        );
      })}
    </WrapperList>
  );
};

export default React.memo(MenuList);

const LinkInner = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 25px;
  }
  @media screen and (max-width: 743px) {
    img {
      width: 6.4vw;
      height: 6.4vw;
    }
  }
`;
const LinkItem = styled.div`
  border-radius: 10px;
  transition: background 0.25s;
  cursor: pointer;
  border: 1px solid transparent;
  a {
    padding: 18px;
    display: block;
  }
  @media screen and (min-width: 744px) {
    &:hover {
      background: #232627;
      border-color: #344054;
      h1 {
        color: #fcfcfd;
        font-weight: 600;
      }
    }
  }
  &.active {
    background: #232627;
    border-color: #344054;
    h1 {
      color: #fcfcfd;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 743px) {
    a {
      padding: 3.2vw;
    }
  }
`;
const WrapperList = styled.div<{ collapse?: boolean }>`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 5px;
  }
  h1 {
    margin-left: 12px;
    color: #667085;
    transition: color 0.25s;
    font-family: SFRegular;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    opacity: ${({ collapse }) => (collapse ? "0" : "1")};
    transition: opacity 0.25s;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 0;
  }
  @media screen and (max-width: 743px) {
    & > div:not(:last-child) {
      margin-bottom: 1.3vw;
    }
    h1 {
      margin-left: 3.2vw;
      font-size: 4.26vw;
    }
  }
`;

const Item = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #ffffff;
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;
  transition: color 0.2s ease;
  @media only screen and (max-width: 1200px) {
    font-size: 1.35vw;
  }
`;
