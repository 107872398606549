import styled from "styled-components";

export const HeaderContainer = styled.div<{ collapse?: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  width: ${({ collapse }) =>
    collapse
      ? "calc(100% - 100px)"
      : "calc(100% - 295px)"};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  background: #141718;
  box-shadow: 10px 6px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  min-height: 78px;
  padding: 24px 28px;
  z-index: 50;
  transition: width .25s;
  @media screen and (max-width: 1080px) {
    width: 100%;
    z-index: 500;
    justify-content: space-between;
    &.show {
      z-index: 10000;
      background-color: #141718;
      box-shadow: none;
      backdrop-filter: unset;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    padding: 20px;
    min-height: 95px;
  }
  /* @media screen and (min-width: 1081px) and (max-width: 1350px) {
    width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 340px)"};
  } */
  @media screen and (max-width: 743px) {
    padding: 20px;
    min-height: 20.8vw;
    align-items: center;
    justify-content: space-between;
  }
`;
export const TitleMobile = styled.p`
  color: #fcfcfd;
  font-family: PPRader;
  font-size: 32px;
  font-weight: 600;
  @media screen and (max-width: 743px) {
    font-size: 8vw;
  }
`;
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  @media screen and (max-width: 743px) {
    gap: 2.66vw;
  }
`;
export const HeaderAction = styled.div`
  cursor: pointer;
  img {
    width: 28px;
    height: 28px;
  }
`;
export const OpenMenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: #344054;
  cursor: pointer;
  span {
    position: relative;
    width: 20px;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
    transition: transform .25s;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &.open {
    span:nth-child(1) {
      top: 3px;
      transform: rotateZ(45deg);
    }
    span:nth-child(2) {
      top: -3px;
      transform: rotateZ(-45deg);
    }
  }
  @media screen and (max-width: 743px) {
    width: 10.13vw;
    height: 10.13vw;
    span {
      width: 5.3vw;
      &:not(:last-child) {
        margin-bottom: 1.3vw;
      }
    }
    &.open {
    span:nth-child(1) {
      top: .8vw;
    }
    span:nth-child(2) {
      top: -1vw;
    }
  }
  }
`;
