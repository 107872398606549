import { Tabs } from "antd";
import { TabsContainer } from "./styled";
import React from "react";

const TabsCommon = ({ activeKey, dataTabs, onChange }: Props) => {
  return (
    <TabsContainer>
      <Tabs onChange={onChange} defaultActiveKey={activeKey}>
        {dataTabs.map((item: any) => {
          return (
            <React.Fragment key={item.key}>
              <Tabs.TabPane
                tab={
                  <p>
                    {item.label}
                  </p>
                }
                key={item.key}
              >
                {item.component}
              </Tabs.TabPane>
            </React.Fragment>
          );
        })}
      </Tabs>
    </TabsContainer>
  );
};

type Props = {
  activeKey: string;
  dataTabs: any;
  onChange: any;
};

export default TabsCommon;
