import { Col, Row, Table } from "antd";
import { TableCommon } from "../../../style/styles";
import { KeyvisualText } from "../Dashboard/styled";
import { PackagesTitle } from "../Invest/styled";
import {
  TransactionContainer,
  TransactionKeyvisual,
  TrasactionBody,
} from "./styled";
import styled from "styled-components";
import Filter from "../../../components/filter";
import useTrasaction from "../../History/useTransaction";
import { useEffect, useState } from "react";
import { FlexBoxPagi } from "../../History/style";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { actGetLisDasboard } from "../../../redux/action/AuthManagerApi";
import { useDispatch } from "react-redux";

const Transaction = () => {
  const { Column } = Table;
  const { setIsPage, isPage, isList, total, totalPage, checkLoading } =
    useTrasaction();
  const [isNumTotalRender, setIsNumTotalRender] = useState(1);
  const [isNumTotalRenderV1, setIsNumTotalRenderV1] = useState(5);
  const handlePageClick = (event: any) => {
    let numsPage = event.selected;
    let numsPages = event.selected + 1;
    setIsPage((prev: any) => ({ ...prev, pageIndex: numsPage.toString() }));
    setIsNumTotalRender(numsPage * 10);
    setIsNumTotalRenderV1(numsPages * 10);
  };

  const dispatch = useDispatch();

  const callApiDelay = () => {
    dispatch(debounce(actGetLisDasboard(), 800));
  };

  useEffect(() => {
    callApiDelay();
  }, [isPage]);
  
  
  return (
    <TransactionContainer>
      <TransactionKeyvisual>
        <h1>Transaction</h1>
        <KeyvisualText>
          <p>Note</p>: Always double-check the recipient address and the amount
          before sending a blockchain transaction.
        </KeyvisualText>
      </TransactionKeyvisual>
      <TrasactionBody>
        <PackagesTitle>
          Transaction History
          <span> {total} </span>
        </PackagesTitle>
        <Filter
          data={[
            { title: "All", img: "" },
            { title: "Deposit", img: "" },
            { title: "WithDraw", img: "" },
            { title: "Transfer", img: "" },
            { title: "Swap", img: "" },
          ]}
        />
        <TableCommon>
          <Table
            dataSource={isList.map((item: any, index: number) => {
              return {
                ...item,
                index: index + 1,
              };
            })}
            onChange={handlePageClick}
            pagination={false}
            footer={() => {
              return (
                <>
                  <ExplainMobile>
                    <div className="active">Active</div>
                    <div className="inactive">InActive</div>
                  </ExplainMobile>
                  <Row>
                    <Col className="gutter-row" md={12} xs={24}>
                      <ShowNumber>
                        Showing
                        <span>1-10</span>
                      </ShowNumber>
                    </Col>
                    <Col className="gutter-row" md={12} xs={24}>
                      <FlexBoxPagi>
                        {isList.length > 0 ? (
                          <ReactPaginate
                            pageCount={Math.ceil(total / 10)}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            pageClassName="page-item"
                            breakClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            previousLabel="<"
                            nextLabel=">"
                          />
                        ) : (
                          ""
                        )}
                      </FlexBoxPagi>
                    </Col>
                  </Row>
                </>
              );
            }}
          >
            <Column
              className="number"
              title="ID"
              dataIndex="Id"
              key="Id"
              render={(Id) => (
                <>
                  <span className="sm">ID: </span>
                  {Id}
                </>
              )}
            />
            <Column
              className="full"
              title="Create At"
              dataIndex="StrCreateOn"
              key="StrCreateOn"
              render={(StrCreateOn) => (
                <>
                  <span className="sm">Create At: </span>
                  {StrCreateOn}
                </>
              )}
            />
            <Column
              className="full"
              title="DESCRIPTION"
              dataIndex="Description"
              key="Description"
              render={(Description) => (
                <>
                  <span className="sm">Description: </span>
                  {Description}
                </>
              )}
            />
            <Column
              className="amount"
              title="Amount"
              dataIndex="StrAmount"
              key="StrAmount"
              render={(Amount) => {
                console.log(Amount);
                
                return <span
                style={{
                  // color: Amount?.toFixed(2) < 0 ? "red" : "green",
                }}
              >
                <span className="sm">Amount: </span>
                {Amount?.replace("KAS" , "")}
              </span>
                // let formattedAmount = Amount.toFixed(2).toString();
                // if (formattedAmount.includes("$")) {
                // } else {
                //   formattedAmount = "$" + formattedAmount;
                // }
                // if (formattedAmount.includes("-")) {
                //   formattedAmount = formattedAmount.replace("$-", "-$");
                // }
                // return (
                //   <>
                //     <span
                //       style={{
                //         color: Amount?.toFixed(2) < 0 ? "red" : "green",
                //       }}
                //     >
                //       <span className="sm">Amount: </span>
                //       {formattedAmount}
                //     </span>
                //   </>
                // );
              }}
            />
            <Column
              className="full"
              title="Status"
              dataIndex="StatusName"
              key="StatusName"
              render={(StatusName) => (
                <>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxWidth: "100px",
                      padding: "6px 28px",
                      backgroundColor: "#039855",
                      borderRadius: "13px",
                    }}
                  >
                    {StatusName}
                  </span>
                </>
              )}
            />
          </Table>
        </TableCommon>
      </TrasactionBody>
    </TransactionContainer>
  );
};

export const ShowNumber = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #eaecf0;
  span {
    margin-left: 5px;
    color: #b692f6;
    font-weight: bold;
    line-height: normal;
  }
  @media screen and (max-width: 743px) {
    font-size: 4.26vw;
    span {
      margin-left: 1.33vw;
    }
  }
`;

export const ExplainMobile = styled.div`
  display: none;
  @media screen and (max-width: 743px) {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    align-items: center;
    gap: 7vw;
    font-size: 3.73vw;
    font-weight: 600;
    color: #d0d5dd;
    & > div {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 2.6vw;
        height: 2.6vw;
        border-radius: 50%;
        left: -15px;
        top: 50%;
        margin-top: -1.6vw;
      }
      &.active::before {
        background-color: #039855;
      }
      &.inactive::before {
        background-color: #d92d20;
      }
    }
  }
`;

export default Transaction;
