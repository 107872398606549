import styled from "styled-components";
import referral_bg from "../../../../assets/network/referral_bg.jpg";
import avatar from "../../../../assets/network/avatar.png";
import { ButtonCommon } from "../../../../style/styles";

export const TreeContainer = styled.div``;
export const TreeHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
  margin-bottom: 30px;
  @media screen and (max-width: 1790px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width: 744px) and (max-width: 1789px) {
    gap: 50px;
  }
  @media screen and (max-width: 743px) {
    gap: 13.33vw;
    margin-bottom: 8vw;
  }
`;
export const TreeReferral = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  min-height: 162px;
  padding: 30px 60px;
  background: url(${referral_bg}) no-repeat center / cover;
  z-index: 0;
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 7, 59, 0.27);
    backdrop-filter: blur(10.5px);
    z-index: -1;
    border-radius: 16px;
    overflow: hidden;
  }
  @media screen and (max-width: 743px) {
    padding: 4vw;
  }
`;
export const ReferralTitle = styled.p`
  margin-bottom: 10px;
  @media screen and (max-width: 743px) {
    font-size: 3.7vw;
    margin-bottom: 2.6vw;
  }
`;
export const ContentBlock = styled.p`
  max-width: 100%;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background: #98a2b3;
  color: #101828;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 743px) {
    font-size: 4.26vw;
    padding: 5.3vw;
    border-radius: 3.2vw;
    margin-bottom: 2.6vw;
  }
`;
export const ReferralContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: 743px) {
    gap: 0;
    flex-wrap: wrap;
    ${ButtonCommon} {
      width: 100% !important;
      min-height: 10vw !important;
    }
  }
`;
export const TreeProfile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 35px 40px 90px;
  max-width: 552px;
  width: 100%;
  border-radius: 17px;
  background: #9e77ed;
  min-height: 162px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -52px;
    background: #232627 url(${avatar}) no-repeat center / 85px 85px;
    width: 108px;
    height: 108px;
    margin-top: -50px;
    border-radius: 50%;
  }
  @media screen and (min-width: 744px) and (max-width: 1789px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 743px) {
    max-width: 100%;
    min-height: 43.2vw;
    padding: 13.33vw 5.3vw 5.3vw;
    &::before {
      width: 22.66vw;
      height: 22.66vw;
      background-size: 18vw 18vw;
      top: 2.5vw;
      left: 50%;
      margin-left: -11.33vw;
    }
  }
`;
export const ProfileLeft = styled.div`
  padding-right: 20px;
`;
export const ProfileRight = styled.div`
  @media screen and (max-width: 743px) {
    margin-top: 10.6vw;
  }
`;
export const ProfileTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  margin-bottom: 5px;
  color: #3538cd;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  border-radius: 22px;
  border: 1px solid #2d31a6;
  background: #a4bcfd;
  @media screen and (max-width: 743px) {
    padding: 1.3vw 4vw;
    margin-bottom: 3.2vw;
    font-size: 4.26vw;
    border-radius: 5.86vw;
    position: relative;
    left: 24vw;
  }
`;
export const ProfileName = styled.div`
  margin-bottom: 5px;
  color: #fcfcfd;
  font-family: Manrope;
  font-size: 24px;
  font-weight: bold;
  @media screen and (max-width: 743px) {
    font-size: 6.4vw;
    margin-bottom: 2.6vw;
  }
`;
export const ProfileEmail = styled.div`
  color: #d0d5dd;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  @media screen and (max-width: 743px) {
    font-size: 3.7vw;
  }
`;
export const TreeTransaction = styled.div`
  .ant-table-pagination.ant-pagination {
    position: relative;
    margin: -30px 5px 0;
    z-index: 10;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-color: #9ca3b0;
    }
    &:hover {
      .ant-pagination-item-link {
        color: #fff;
        border-color: #fff;
      }
      span {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
  }
  .ant-pagination-item-link,
  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 33px;
    height: 33px;
    color: #fff;
    &:hover a,
    &:hover span {
      color: #fff;
    }
    &.ant-pagination-item-active {
      border-color: #6b3796;
      background-color: #6b3796;
      color: #fff;
    }
  }
  .ant-pagination-item {
    border-color: #9ca3b0;
    &:hover {
      border-color: #9ca3b0;
    }
  }
  @media only screen and (max-width: 743px) {
    .ant-pagination-item-link,
    .ant-pagination-item {
      width: 8.8vw;
      height: 8.8vw;
    }
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin-right: 2.13vw;
    }
  }
`;
