import React, { useEffect, useState, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { useActiveWeb3React, useConnectWallet } from "../../hooks";
import { WALLET_LIST } from "../../constants/index";
import WalletItem from "./WalletItem";
import { ModalProps } from "./config";
import { CloseConnectIcon } from "../Svg";
import {
  BoxModalContent,
  BoxPd,
  ModalText,
  BoxHeader,
  ConnectWalletContainer,
  ConnectZone,
  ZoneBox,
  ZoneIcon,
  ModalSubText,
} from "./styled";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import changeNetwork from "./funcChanngeNetword";
import { BoxCommon, ButtonCloseModal, ButtonCommon } from "../../style/styles";
import change_icon from "../../assets/connectWallet/change.svg";
import logo from "../../assets/dashboard/logo.svg";
import add from "../../assets/connectWallet/add.svg";
import meta_mask from "../../assets/connectWallet/meta_mask.svg";
import wallet_connect from "../../assets/connectWallet/wallet_connect.svg";
import Web3 from "web3";
import { SafePalWallet, TrustWallet } from "../Svg/IconsWallet";
import { useWeb3Modal } from "@web3modal/wagmi/react";


declare const window: Window & typeof globalThis & { ethereum: any };

const ConnectWallet: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = "24px",
  headerBackground = "transparent",
  minWidth = "320px",
  ...props
} : any) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { walletLogin, walletLogout } = useConnectWallet();
  const chainId = process.env.REACT_APP_CHAIN_ID ?? "0";
  const context = useWeb3React();
  const { open } = useWeb3Modal();
  const { active } = context;
  const { onClose } = useContext(ModalConfirmContext)!;

  const [hover, setHover] = useState(-1);

  if (window.ethereum) {
    window.ethereum.on("chainChanged", (chainId: any) => {
      // window.location.reload();
      localStorage.setItem("isCheck", "2");
    });

    window.ethereum.on("accountsChanged", (chainId: string) => {
      // window.location.reload();
      localStorage.setItem("isCheck", "2");
    });
  }

  // ---- HANDLE DISCONNECT ----
  const handleDisconnect = () => {
    walletLogout();
    setIsModalVisible(false);
    onClose?.();
  };

  // ---- HANDLE CONNECT ----
  const handleConnect = async (connectorId: string) => {
    try {
      const rs = await walletLogin(connectorId);
      setIsModalVisible(false);
      localStorage.setItem("isCheck", "1");
      onClose?.();
    } catch (e) {
      console.error("Login failed");
    }
  };
  useEffect(() => {
    if (window.ethereum) {
      if (chainId !== window.ethereum.networkVersion) {
        changeNetwork(`0x${parseInt(chainId).toString(16)}`);
      }
    }
  }, []);

  const [isSafePalInstalled, setIsSafePalInstalled] = useState(false);

  useEffect(() => {
    if (window?.ethereum) {
      const web3 = new Web3(window?.ethereum);
      if (web3?.currentProvider) {
        if (typeof web3?.currentProvider !== "string") {
          if ("isSafePal" in web3?.currentProvider) {
            setIsSafePalInstalled(true);
          }
        }
      }
    }
  }, []);

  // const CWNew = useAccount();
  // console.log("CWNew",CWNew.isConnected);
  // const {contextCW} = useActiveWeb3React()
  // console.log("CWNew",contextCW);

  return (
    <ConnectWalletContainer>
      {!active ? (
        <>
          <BoxCommon icon={true} style={{ maxWidth: "1190px" }}>
            <>
              <ButtonCloseModal onClick={onClose}>
                <CloseConnectIcon />
              </ButtonCloseModal>
              {!active ? (
                <BoxHeader>
                  <ConnectZone>
                    <ZoneBox>
                      <img src={logo} alt="token" />
                    </ZoneBox>
                    <ZoneIcon>
                      <img src={change_icon} alt="change" />
                    </ZoneIcon>
                    <ZoneBox>
                      {hover === 0 ? (
                        <img src={meta_mask} alt="wallet" />
                      ) : hover === 1 ? (
                        <img src={wallet_connect} alt="wallet" />
                      ) : hover === 2 ? (
                        <SafePalWallet />
                      ) : hover === 3 ? (
                        <TrustWallet />
                      ) : (
                        <img src={add} alt="wallet" />
                      )}
                    </ZoneBox>
                  </ConnectZone>
                  <ModalText>Connect your wallet</ModalText>
                  <ModalSubText>
                    Always double-check the wallet address before connecting to
                    a web app or dApp. Only connect your wallet to trusted
                    websites and dApps.
                  </ModalSubText>
                </BoxHeader>
              ) : (
                <></>
              )}
            </>
            {!active ? (
              <BoxPd>
                <BoxModalContent>
                  {WALLET_LIST.map((wallet, index) => {
                    if (
                      wallet.title === "Metamask" &&
                      !window?.ethereum &&
                      !window?.ethereum?.isMetaMask
                    ) {
                      return (
                        <WalletItem
                          setHover={setHover}
                          index={index}
                          onClick={() => {
                            window.location.href = `https://metamask.app.link/${window.location.hostname}${window.location.pathname}`;
                          }}
                          key={wallet.title}
                          icon={<wallet.icon width="48px" />}
                          title={wallet.title}
                          describe={wallet.describe}
                        />
                      );
                    } else if (
                      wallet.title === "SafePal Wallet" &&
                      !isSafePalInstalled
                    ) {
                      return (
                        <WalletItem
                          setHover={setHover}
                          className={`cus-wallet SafePal`}
                          onClick={() => {
                            window.location.href = `https://chrome.google.com/webstore/detail/safepal-extension-wallet/lgmpcpglpngdoalbgeoldeajfclnhafa`;
                          }}
                          key={wallet.title}
                          icon={<wallet.icon width="48px" />}
                          title={wallet.title}
                          describe={wallet.describe}
                        />
                      );
                    }
                    else if (
                      wallet.title === "Wallet Connect"
                    ) {
                      return (
                        <WalletItem
                          setHover={setHover}
                          className={`cus-wallet`}
                          onClick={() => {
                            open();
                            onClose?.();
                          }}
                          key={wallet.title}
                          icon={<wallet.icon width="48px" />}
                          title={wallet.title}
                          describe={wallet.describe}
                        />
                      );
                    }
                    else {
                      return (
                        <WalletItem
                          setHover={setHover}
                          index={index}
                          key={wallet.title}
                          onClick={() => {
                            handleConnect(wallet.connectorId);
                          }}
                          icon={<wallet.icon width="48px" />}
                          title={wallet.title}
                          describe={wallet.describe}
                        />
                      );
                    }
                  })}
                </BoxModalContent>
              </BoxPd>
            ) : (
              <></>
              // <ButtonCommon
              //   onClick={() => {
              //     handleDisconnect();
              //   }}
              // >
              //   Disconnect
              // </ButtonCommon>
            )}
          </BoxCommon>
        </>
      ) : (
        <>
          <ButtonCommon
            onClick={() => {
              handleDisconnect();
            }}
          >
            Disconnect
          </ButtonCommon>
        </>
      )}
    </ConnectWalletContainer>
  );
};

export default React.memo(ConnectWallet);
