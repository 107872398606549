import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Essentials/close cross/circle">
          <path
            id="Icon"
            d="M12.4998 7.49996L9.99983 9.99996M9.99983 9.99996L7.49984 12.5M9.99983 9.99996L12.4998 12.5M9.99983 9.99996L7.49984 7.49996M18.3332 9.99996C18.3332 14.6023 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C14.6022 1.66663 18.3332 5.39759 18.3332 9.99996Z"
            stroke="#667085"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </g>
      </svg>
  );
};

export default Icon;
