import styled from "styled-components";

export const MenuWrapper = styled.div<{ collapse?: boolean }>`
  position: fixed;
  left: 0;
  top: 0px;
  background: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ collapse }) => (collapse ? "24px 20px" : "24px")};
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
  max-width: ${({ collapse }) => (collapse ? "100px" : "295px")};
  width: 100%;
  background: #141718;
  min-height: 100vh;
  z-index: 90;
  transition: all 0.25s;
  cursor: pointer;
  @media screen and (max-width: 1080px) {
    max-width: 100%;
    transform: translateX(-1000px);
    min-height: auto;
    &.isShowModal {
      transform: translateX(0);
      transition: transform 5s;
    }
  }
  .menu-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media screen and (min-width: 744px) {
    .menu-mobile {
      padding-bottom: 10px;
    }
    .mobile-box {
      margin-bottom: 50px;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    .menu-mobile {
      min-height: calc(100vh - 118px);
      padding-bottom: 10px;
    }
  }
  /* @media screen and (min-width: 1081px) and (max-width: 1350px) {
    max-width: ${({ collapse }) => (collapse ? "100px" : "342px")};
    padding-left: 20px;
    padding-right: 20px;
  } */
  @media screen and (max-width: 743px) {
    padding: 10vw 5.3vw 10vw;
    margin-top: 20vw;
    height: calc(100vh - 20vw);
    .menu-mobile {
      overflow-y: auto;
      height: calc(100vh - 20vw);
      padding-bottom: 2.6vw;
      .mobile-box {
        margin-bottom: 5vw;
      }
    }
  }
`;
export const WrapperMobile = styled.div`
  .bt-mobile {
    background: transparent;
    height: auto;
    padding-right: 10px;
    box-shadow: none !important;
  }
  @media only screen and (max-width: 968px) {
    display: flex;
  }
`;

export const BoxMenu = styled.div`
  width: 100%;
  @media screen and (max-width: 743px) {
    padding-top: 2vw;
  }
`;

export const MenuLangdingWapper = styled.div<{ isScroll?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  z-index: 90;
  height: 90px;
  @media only screen and (min-width: 1440px) {
    background: transparent;
  }
  @media only screen and (max-width: 1200px) {
    padding: 32px 51px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px 24px;
  }
  button {
    position: relative;
    width: 129px;
    height: 36px;
    background: linear-gradient(275.32deg, #004eff 0.5%, #0047e8 98.34%);
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.25s;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      margin: -2px;
      background-image: linear-gradient(
        265.98deg,
        #004eff -2.85%,
        #6bd0ff 101.88%
      );
      border-radius: 4px;
      transition: all 0.25s;
    }
    &:hover {
      width: 136px;
      font-weight: bold;
      background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
      box-shadow: 0px 0px 24px rgba(0, 78, 255, 0.75);
      &::before {
        background: linear-gradient(265.47deg, #004eff 0%, #0542cc 100%);
      }
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 743px) {
    button {
      width: 34.68vw;
      height: 10vw;
      font-size: 4.3vw;
      &:hover {
        width: 37vw;
      }
    }
  }
`;
