import { useMemo, useContext } from "react";
import { ConnectWallet } from "../Connect";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { ButtonCommon } from "../../style/styles";
import { useMatchBreakpoints } from "@raca2022/uikit";
import { useAccount, useChainId, useBalance } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";


const Connect = () => {
  const { isDesktop } = useMatchBreakpoints();
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const { onOpen } = useContext(ModalConfirmContext)!;
  const customAccount = useMemo<any>(() => {
    if (address) {
      return `${address.substr(0, 4)}...${address.substr(address.length - 4)}`;
    }
    return `Connect Wallet`;
  }, [address]);

  const isCWLogin = localStorage.getItem("CWModal");

  const handleOpenCW = () => {
    // if (isCWLogin === "false" && !isDesktop) {
    //   onOpen(<ConnectWallet />);
    // } else if (isCWLogin) {
      
      open();
    // } else if (!isDesktop) {
    //   open();
    // } else {
    //   onOpen(<ConnectWallet />);
    // }
  };

  return (
    <ButtonCommon onClick={() => handleOpenCW()}>{customAccount}</ButtonCommon>
  );
};

export default Connect;
