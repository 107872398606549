import { Box, useMatchBreakpoints } from "@raca2022/uikit";
import {
  CollapseButton,
  InfoStatus,
  LogoText,
  MenuHeader,
  MenuPackage,
  PackageInfo,
} from "../Menu/styled";
import { BoxMenu, MenuWrapper } from "../Menu/styledMenu";
import MenuList from "../Menu/MenuList";
import { ButtonCommon } from "../../style/styles";

// Images
import logo from "../../assets/dashboard/logo.svg";
import collapse_icon from "../../assets/dashboard/collapse.svg";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Aside = () => {
  const { isShowModalMenu, collapseMenu, collapse, onCloseMenu } =
    useContext(ModalConfirmContext)!;
  const { isDesktop } = useMatchBreakpoints();
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const { listUser }: any = useSelector<any>((state) => state.auth);

  return (
    <MenuWrapper
      onClick={(e) => {
        !isDesktop && collapseMenu();
        e.stopPropagation();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      collapse={collapse}
      className={isDesktop && isShowModalMenu && "isShowModal"}
    >
      {!isDesktop ? (
        <>
          <MenuHeader>
            <LogoText
              href="/"
              collapse={collapse}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src={logo} alt="logo" />
              <span>Digital World</span>
            </LogoText>
            <>
              <CollapseButton
                hover={hover}
                collapse={collapse}
                className={collapse ? "collapseIcon" : ""}
                onClick={() => collapseMenu()}
              >
                <img src={collapse_icon} alt="collapse" />
              </CollapseButton>
            </>
          </MenuHeader>
        </>
      ) : (
        <></>
      )}
      <div className="menu-mobile" style={{ width: "100%" }}>
        <Box className="mobile-box">
          <BoxMenu>
            <MenuList />
          </BoxMenu>
        </Box>
        {listUser && <MenuPackage levelName={listUser?.LevelName} style={{ opacity: collapse ? "0" : "1" }}>
          <PackageInfo>
            <InfoStatus LevelName={listUser?.LevelName}>
              {listUser?.LevelName}
            </InfoStatus>
          </PackageInfo>
          {listUser?.LevelName === "Not a member" && (
            <ButtonCommon
              onClick={(e) => {
                if (listUser?.LevelName === "Not a member") {
                  history.push("/invest");
                }
                e.stopPropagation();
                if (isDesktop) {
                  onCloseMenu();
                }
              }}
              transparent={true}
              borderBlack={true}
              style={{ minHeight: "52px", width: "100%", color: "#706282" }}
            >
              {listUser?.LevelName === "Not a member"
                ? "Register Member"
                : listUser?.LevelName}
            </ButtonCommon>
          )}
        </MenuPackage>}
      </div>
    </MenuWrapper>
  );
};

export default Aside;
