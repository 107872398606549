import axios from "axios";
import { TOKEN, DOMAIN_DW } from "../settings/config";

const Api = axios.create({
  baseURL: DOMAIN_DW,
});

Api.interceptors.request.use(
  function (config: any) {
    const toKenUser = localStorage.getItem(TOKEN)!!;
    config.headers = {
      ...config.headers,
      "DW-User-Token": localStorage.getItem(TOKEN)!!,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export { Api };
