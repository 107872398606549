import styled from "styled-components";
import { Box, Text, Flex } from "@raca2022/uikit";
import { BoxCommon, ButtonCommon } from "../../style/styles";
import keyvisualBg from "../../assets/common/keyvisualBg.jpg";

export const BoxModal = styled(Box)`
  position: relative;
  width: 410px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid var(--colors-primary-colors-gray-500, #667085);
  background: rgba(29, 20, 30, 0.62);
  @media only screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const BoxPd = styled(Box)`
  padding-top: 0;
  position: relative;
`;

export const BoxPdDisconnect = styled(Box)`
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  z-index: 11;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .text-custom {
    font-family: Monda !important;
    color: #fff;
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    background-color: transparent;
    padding: 8px;
    border-radius: 10px;
    display: flex;
    gap: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
    height: 62px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const BoxModalContent = styled(Box)`
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  & > * {
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #111928;
  }
  .wallet-flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding: 25px 12px;
    width: 100%;
    transition: 0.3s ease;
    border-radius: 12px;
    background: #f3f4f6;
    cursor: pointer;
    border: 2px solid transparent;
    p {
      color: #111928;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }
    span {
      color: #4b5563;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    &:hover {
      border-color: #b692f6;
      box-shadow: 0px 0px 30px 0px rgba(186, 89, 165, 0.45);
    }
    .wallet-icon {
      margin-bottom: 10px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  @media screen and (max-width: 743px) {
    .wallet-flex {
      padding: 10px;
      span {
        font-size: 12px;
      }
      .wallet-icon {
        margin-bottom: 5px;
      }
    }
  }
`;

export const BoxModalDisConnect = styled(Box)`
  .btn-connect {
    margin: 0 20px;
    width: 165px;
    height: 50px;
    background: linear-gradient(
      267.66deg,
      rgb(200, 255, 77) 0.33%,
      rgb(154, 222, 0) 98.08%
    );
    border: 2px solid rgb(229, 255, 171);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;

    :hover {
      background: #ea6e6e;
      color: #671b1b;
    }
  }

  .noti-out-text {
    font-weight: 500;
    text-align: center;
    font-size: 22px;
    color: #fffdfd;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 570px) {
    min-width: auto;
  }
`;

export const ModalText = styled(Text)`
  font-size: 32px;
  font-family: SFRegular;
  line-height: 120%;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fcfdfe;
  letter-spacing: -0.8px;
  margin-bottom: 10px;
  @media screen and (max-width: 743px) {
    font-size: 28px;
  }
`;

export const ModalSubText = styled(Text)`
  font-size: 16px;
  font-family: SFRegular;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #fcfdfe;
  line-height: 22px;
  @media screen and (max-width: 743px) {
    font-size: 14px;
  }
`;

export const BoxHeader = styled(Flex)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 314px;
  background: url(${keyvisualBg}) no-repeat center / cover;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 36px;
  text-align: center;
  @media screen and (max-width: 743px) {
    padding: 20px;
    justify-content: center;
    min-height: auto;
  }
`;

export const ConnectWalletContainer = styled.div`
  width: 643px;
  ${BoxCommon} {
    border-radius: 16px;
    border: 1px solid #667085;
    background: rgba(29, 20, 30, 0.62);
    padding: 35px 58px;
  }
  ${ButtonCommon} {
    margin: 0 auto;
  }
  @media only screen and (max-width: 743px) {
    width: 100%;
    ${BoxCommon} {
      padding: 15vw 3vw 5.3vw;
    }
  }
  @media screen and (max-width: 743px) {
    max-height: 550px;
    overflow-y: auto;
  }
`;
export const ConnectZone = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 15px;
`;
export const ZoneBox = styled.div`
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid rgba(100, 90, 208, 0.5);
  background: #f3f4f6;
  img {
    width: 36px;
    height: 36px;
  }
`;
export const ZoneIcon = styled.div``;
