import {
  BinanceWallet,
  Metamask,
  SafePalWallet,
  TrustWallet,
  WalletConnect,
  TokenPocket,
  MathWallet,
} from "../components/Svg/IconsWallet";
export const API_URL = process.env.REACT_APP_API_URL;
export const NetworkContextName = "NETWORK";
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;
export const ADDRESS_USD: any = {
  56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  1: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  137: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  250: "0xAd84341756Bf337f5a0164515b1f6F993D194E1f",
};
export const ADDRESS_USDT: any = {
  56: "0x55d398326f99059fF775485246999027B3197955",
};
// export const ADDRESS_RACA: any = {
//   56: "0x12BB890508c125661E03b09EC06E404bc9289040",
// };
export const ADDRESS_FBD: any = {
  56: "0x862a65D31DC45a0dfCcBB4DE5659137A6589c52a",
};
export const ADDRESS_BUSD: any = {
  56: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
};
export const INVES_CONTRACT: any = {
  56: "0x7Df2A33bFCD76240b796b0FBBEd406aa1FCce830",
  97: "0xE1068958C357e84f2C065D8C9b9f15C70028B546",
};
// export const ADDRESS_RECEVIE_TOKEN = "0x93e6b26e93A41b7EcA710c942Ea167fC6A469792";
export const ADDRESS_RECEVIE_TOKEN =
  "0xC319735ac5A8f1bEae5630fb8Ba5090D8d2bd4Ee";
export const KEY_CRYPTOJS = "4kHlZXyYTew38QnxvWFnag==";

// wallet list
export const WALLET_LIST = [
  {
    icon: Metamask,
    title: "Metamask",
    connectorId: "injected",
    describe:
      "A digital wallet that allows you to store, send, and receive cryptocurrencies.",
  },
  // { icon: BinanceWallet, title: "Binance Wallet", connectorId: "injected" },
  {
    icon: WalletConnect,
    title: "Wallet Connect",
    connectorId: "walletconnect",
    describe:
      "A digital wallet that allows you to store, send, and receive cryptocurrencies.",
  },
  {
    icon: SafePalWallet,
    title: "SafePal Wallet",
    connectorId: "injected",
    describe:
      "A digital wallet that allows you to store, send, and receive cryptocurrencies.",
  },
  {
    icon: TrustWallet,
    title: "Trust Wallet",
    connectorId: "walletconnect",
    describe:
      "A digital wallet that allows you to store, send, and receive cryptocurrencies.",
  },
  // { icon: TokenPocket, title: 'TokenPocket', connectorId: 'injected' },
  // { icon: MathWallet, title: 'Math Wallet', connectorId: 'injected' }
];
export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;

export const MAINNET_BSC_URL = "https://bsc-dataseed.binance.org";
export const MAINNET_CHAIN_ID = 56;
// export const marketContractAddress = '0x7c76E1344A79fFe41Dd43239e30D60C57981dbF2';
