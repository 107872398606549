import React from "react";
import { Provider } from "react-redux";
import { ModalProvider, ModalProviderNone } from "@raca2022/uikit";
import { MenuProvider } from "./components/Menu/context/Context";
import { store } from "./redux/configStore";
import { getLibrary, Web3ProviderNetwork } from "./utils/context/web3provider";
// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";
// import { Web3Modal } from "@web3modal/react";
// // import { configureChains, createConfig, WagmiConfig } from "wagmi";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { arbitrum, bsc, opBNB, zetachainAthensTestnet } from "wagmi/chains";
import { ToastContainer } from "react-toastify";
import { Web3ReactProvider } from "@web3-react/core";

type Props = {
  children: React.ReactNode;
};
const projectId = "c9922336990ed755f7ac820ffc2a5b00";
if (!projectId) {
  throw new Error("PROJECT_ID is not set");
}
const chains = [
  bsc,
  // arbitrum,
  // opBNB,
  // zetachainAthensTestnet,
];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
  themeVariables: {
    "--w3m-z-index": 9999,
  },
  chainImages: {
    7001: "/assets/icon/icon-zeta.png",
    204: "/assets/icon/opbnb.png", 
  },
});

const Providers: React.FC<Props> = ({ children }) => {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <MenuProvider>{children}</MenuProvider>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </WagmiConfig>
    </>
  );
};

export default Providers;
