import styled from "styled-components";

export const TabsContainer = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    &:before {
      border-bottom: 0;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
  }
  .ant-tabs-nav-list {
    border-radius: 18px;
    background: #141718;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 6px 25px;
    transition: background 0.25s;
    border-radius: 18px;
    .ant-tabs-tab-btn {
      font-family: Manrope;
      font-size: 16px;
      color: #D0D5DD;
      font-weight: 400;
      transition: color .15s;
      letter-spacing: .05em;
    }
    &.ant-tabs-tab-active {
      background-color: #D0D5DD;
      border-radius: 18px;
      .ant-tabs-tab-btn {
        font-weight: bold;
        color: #101828;
      }
    }
  }
  .ant-tabs-content-holder {
    text-align: left;
  }
  @media screen and (max-width: 743px) {
    width: 100%;
    .ant-tabs-tab {
      padding: 2.6vw 4vw;
      border-radius: 4.8vw;
    }
    .ant-tabs-tab.ant-tabs-tab-active,
    .ant-tabs-nav-list {
      border-radius: 4.8vw;
    }
    .ant-tabs > .ant-tabs-nav {
      margin-bottom: 5.3vw;
    }
    .ant-tabs-tab .ant-tabs-tab-btn,
    .ant-tabs-tab .ant-tabs-tab-btn p {
      font-size: 3.7vw;
    }
  }
`;
