import {
  useMatchBreakpoints,
} from "@raca2022/uikit";
import {
  MenuLangdingWapper,
} from "./styledMenu";
import { Link, useLocation } from "react-router-dom";
import Aside from "../aside";

const Menu = () => {
  const { isDesktop } = useMatchBreakpoints();
  const pathname = useLocation().pathname;

  return (
    <>
      {pathname === "/" ? (
        <MenuLangdingWapper>
          <span>Digital World</span>
          <Link to="/dashboard">
            <button>Launch App</button>
          </Link>
        </MenuLangdingWapper>
      ) : (
        <>
          {!isDesktop && <Aside />}
        </>
      )}
    </>
  );
};

export default Menu;
