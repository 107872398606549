import styled from "styled-components";

const Loading = () => {
  return (
    <Wrapper>
      <div className="spinner spinnerMax">
        <div className="spinner spinnerMid">
          <div className="spinner spinnerMin"></div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Loading;

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    align-items: center;
    border: 0.3em solid transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
  }

  .spinnerMax {
    border-top: 0.3em solid #c44cff;
    border-right: 0.3em solid #c44cff;
    animation: spinnerOne 3s linear infinite;
    height: 4.6em;
    width: 4.6em;
  }

  .spinnerMid {
    border-top: 0.3em solid #8b80e0;
    border-right: 0.3em solid #8b80e0;
    animation: spinnerOne 5s linear infinite;
    height: 4em;
    width: 4em;
  }

  .spinnerMin {
    border-top: 0.3em solid #c44cff;
    border-right: 0.3em solid #c44cff;
    animation: spinnerOne 5s linear infinite;
    height: 3.4em;
    width: 3.4em;
  }

  @keyframes spinnerOne {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
