import { DatePicker, Select } from "antd";
import {
  ButtonFilter,
  FilterContainer,
  FilterSelect,
  FilterWrapper,
} from "./styled";
import arrow from "../../assets/common/filter/arrow.svg";
import filter from "../../assets/common/filter/filter.svg";
import calendar from "../../assets/common/filter/calendar.svg";
import narrow_right from "../../assets/common/filter/arrow-narrow-right.svg";
import narrow_left from "../../assets/common/filter/arrow-narrow-left.svg";
import moment from "moment";

const Filter = ({ data }: any) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  
  return (
    <FilterContainer>
      <FilterWrapper>
        <FilterSelect>
          <Select
            suffixIcon={<img src={arrow} alt="arrow" />}
            placeholder="Please select chain"
            defaultValue={data[0]?.title}
            style={{ width: "100%" }}
          >
            {data?.map((item: any, index: number) => (
              <Option
                className="option-filter"
                key={index}
                value={item.title}
                label={item.title}
              >
                <div>
                  {item.img === "" ? "" : <img src={item.img} alt="icon" />}
                  {item.title}
                </div>
              </Option>
            ))}
          </Select>
          <RangePicker
            defaultValue={[
              moment( new Date() , dateFormat),
              moment(new Date(), dateFormat),
            ]}
            getPopupContainer={(trigger:any) => trigger.parentNode}
            inputReadOnly={true}
            format={dateFormat}
            size="small"
            suffixIcon={<img src={calendar} alt="calendar"/>}
            prevIcon={<img src={narrow_left} alt="narrow_left"/>}
            nextIcon={<img src={narrow_right} alt="narrow_right"/>}
            superNextIcon={<></>}
            superPrevIcon={<></>}
          />
        </FilterSelect>
        <ButtonFilter>
          <img src={filter} alt="filter" />
          Filter
        </ButtonFilter>
      </FilterWrapper>
    </FilterContainer>
  );
};

export default Filter;
