import Connect from "../Menu/ButtonConnect";
import {
  HeaderAction,
  HeaderContainer,
  HeaderRight,
  OpenMenuMobile,
  TitleMobile,
} from "./styled";

import setting from "../../assets/dashboard/setting.svg";
import notification from "../../assets/dashboard/notification.svg";
import logo from "../../assets/dashboard/logo.svg";
import { useMatchBreakpoints } from "@raca2022/uikit";
import { LogoText } from "../Menu/styled";
import { ModalConfirmContext } from "../ProviderPopUp/confirm";
import { useContext } from "react";
import Aside from "../aside";
import InputSearch from "../inputSearch";
import FormProfile from "../formProfile";

const HeaderDashborad = () => {
  const { isDesktop } = useMatchBreakpoints();
  const { onOpenMenu, isShowModalMenu, onCloseMenu, collapse, onOpen } =
    useContext(ModalConfirmContext)!;
  return (
    <HeaderContainer
      collapse={collapse}
      className={isDesktop && isShowModalMenu ? "show" : ""}
    >
      {!isDesktop ? (
        <></>
      ) : (
        // <InputSearch />
        <>
          {!isShowModalMenu ? (
            <LogoText href="/">
              <img src={logo} alt="logo" />
              <p style={{ marginTop: "1vw" }}>Digital World</p>
            </LogoText>
          ) : (
            <>
              <TitleMobile>Menu</TitleMobile>
              <HeaderAction
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  onOpen(<FormProfile />);
                  onCloseMenu();
                }}
              >
                <img src={setting} alt="setting" />
              </HeaderAction>
            </>
          )}
        </>
      )}
      <HeaderRight>
        {!isDesktop && (
          <>
            <HeaderAction onClick={() => onOpen(<FormProfile />)}>
              <img src={setting} alt="setting" />
            </HeaderAction>
            <HeaderAction>
              <img src={notification} alt="notification" />
            </HeaderAction>
          </>
        )}
        {!isShowModalMenu && (
          <>
            <Connect />
          </>
        )}
        {isDesktop && (
          <>
            <OpenMenuMobile
              className={isShowModalMenu ? "open" : "hide"}
              onClick={() => {
                isShowModalMenu ? onCloseMenu() : onOpenMenu(<Aside />);
              }}
            >
              <span></span>
              <span></span>
            </OpenMenuMobile>
          </>
        )}
      </HeaderRight>
    </HeaderContainer>
  );
};

export default HeaderDashborad;
