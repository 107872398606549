import { useEffect, useState, useMemo } from "react";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { actGetLisAffiliates } from "../../redux/action/AuthManagerApi";
import { useJwt } from "../../hooks";
import { TOKEN } from "../../redux/settings/config";
import { useAccount } from "wagmi";

const useAffiliates = () => {
  const { address } = useAccount();
  const dispatch = useDispatch();
  const { listAffiliates, authLogin }: any = useSelector<any>(
    (state) => state.auth
  );
  const { accountStatus } = useJwt();
  const [isAffiliation, setIsAffiliation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const callApiDelay = () => {
    setIsLoading(true);
    dispatch(debounce(actGetLisAffiliates(), 800));
  };

  useEffect(() => {
    if (
      (address) &&
      // accountStatus === "1" &&
      localStorage.getItem(TOKEN)!!
    ) {
      callApiDelay();
    }
  }, [ address, accountStatus, authLogin]);

  useMemo(() => {
    if (listAffiliates !== null) {
      setIsAffiliation(listAffiliates);
      setIsLoading(false);
    }
  }, [listAffiliates]);

  useEffect(() => {
    if (!isLoading && !address) {
      setIsAffiliation([]);
      setIsLoading(false);
      return;
    }
  }, [isLoading,  address]);

  return {
    isAffiliation,
    isLoading,
  };
};

export default useAffiliates;
