import styled from "styled-components";

import keyvisual_bg from "../../../assets/transaction/transaction_keyvisual.jpg";
import { PackagesTitle } from "../Invest/styled";
import { ButtonCommon } from "../../../style/styles";
import { KeyvisualText } from "../Dashboard/styled";

export const TransactionContainer = styled.div``;
export const TransactionKeyvisual = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 285px;
  padding: 75px 40px;
  background: url(${keyvisual_bg}) no-repeat center / cover;
  border-radius: 16px;
  margin-bottom: 30px;
  h1 {
    margin-bottom: 15px;
    color: #fff;
    font-family: Manrope;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
  }
  ${KeyvisualText} {
    text-align: center;
  }
  @media screen and (max-width: 743px) {
    border-radius: 0;
    min-height: 66.6vw;
    padding: 7.46vw 10vw;
    text-align: center;
    margin: -5.5vw -10.1vw 5.33vw;
    h1 {
      font-size: 9.6vw;
      margin-bottom: 6vw;
    }
    ${ButtonCommon} {
      margin: 0 auto;
    }
  }
`;
export const TrasactionBody = styled.div`
  ${PackagesTitle} {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 743px) {
    min-height: 100vh;
    ${PackagesTitle} {
      margin-bottom: 5.3vw;
    }
  }
`;
