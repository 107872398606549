// @ts-ignore
import CopyToClipboard from "react-copy-to-clipboard";
import {
  ContentBlock,
  ProfileEmail,
  ProfileLeft,
  ProfileName,
  ProfileRight,
  ProfileTag,
  ReferralContent,
  ReferralTitle,
  TreeContainer,
  TreeHeader,
  TreeProfile,
  TreeReferral,
  TreeTransaction,
} from "./styled";
import { Col, Row, Table, message } from "antd";
import { ButtonCommon, TableCommon } from "../../../../style/styles";
import { PackagesTitle } from "../../Invest/styled";
import { ExplainMobile, ShowNumber } from "../../Transaction";
import { useMatchBreakpoints } from "@raca2022/uikit";
import useAffiliates from "../../../affiliates/useAffiliates";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import {
  actGetLisDasboard,
  actUserLichSuDauTu,
} from "../../../../redux/action/AuthManagerApi";
import { useEffect } from "react";
// import { FlexBoxPagi } from "../../../History/style";
// import ReactPaginate from "react-paginate";
// import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

const Tree = () => {
  const { Column } = Table;
  const { isSm } = useMatchBreakpoints();
  const { address } = useAccount();
  const { isAffiliation } = useAffiliates();
  const { listUser }: any = useSelector<any>((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(debounce(actGetLisDasboard(), 800));
  }, []);
  const accountEllipsis = `https://${
    window.location.hostname
  }/dashboard?referral=${address?.substring(0, 10)}...${address?.substring(
    address?.length - 4
  )}`;

  const accountEllipsis560 = `https://${
    window.location.hostname
  }/dashboard?referral=${address?.substring(0, 10)}...${address?.substring(
    address?.length - 4
  )}`;

  const linkReferral = `https://${
    window.location.hostname
  }/dashboard?referral=${address || `TBA`}`;

  // const [isPage, setIsPage] = useState({
  //   pageIndex: "0",
  //   pageSize: "10",
  //   type: -1,
  // });
  // const [total, setTotal] = useState<any>(0);

  // const handlePageClick = (event: any) => {
  //   let numsPage = event.selected;
  //   let numsPages = event.selected + 1;
  //   setIsPage((prev: any) => ({ ...prev, pageIndex: numsPage.toString() }));
  // };

  // useMemo(() => {
  //   if (isAffiliation !== null) {
  //     setTotal(isAffiliation.length);
  //     return;
  //   }
  // }, [isAffiliation]);

  return (
    <TreeContainer>
      <TreeHeader>
        <TreeReferral>
          <div style={{ width: "100%" }}>
            <ReferralTitle>Referral Link</ReferralTitle>
            <ReferralContent>
              <ContentBlock>
                {!address
                  ? "Please Connect Your Wallet"
                  : listUser?.TotalInvestUSD
                  ? isSm
                    ? accountEllipsis560
                    : accountEllipsis
                  : "Please Investment to get the Referral Link"}
              </ContentBlock>
              {listUser?.TotalInvestUSD ? (
                <CopyToClipboard
                  text={linkReferral}
                  onCopy={() => {
                    toast.success("Coppied !!!");
                  }}
                >
                  <ButtonCommon>Copy</ButtonCommon>
                </CopyToClipboard>
              ) : (
                <></>
              )}
            </ReferralContent>
          </div>
        </TreeReferral>
      </TreeHeader>
      <TreeTransaction>
        {/* <PackagesTitle>Affiliates History</PackagesTitle> */}
        <TableCommon>
          <Table
            dataSource={isAffiliation.map((item: any, index: number) => {
              return {
                ...item,
                index: index + 1,
              };
            })}
            // onChange={handlePageClick}
            // pagination={false}
            footer={() => {
              return (
                <>
                  <ExplainMobile>
                    <div className="active">Active</div>
                    <div className="inactive">InActive</div>
                  </ExplainMobile>
                  <Row>
                    <Col className="gutter-row" md={12} xs={24}>
                      <ShowNumber>
                        Showing
                        <span>1-10</span>
                      </ShowNumber>
                    </Col>
                    {/* <Col className="gutter-row" md={12} xs={24}>
                      <FlexBoxPagi>
                        {isAffiliation.length > 0 ? (
                          <ReactPaginate
                            pageCount={Math.ceil(total / 10)}
                            // pageRangeDisplayed={1}
                            // marginPagesDisplayed={1}
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            pageClassName="page-item"
                            breakClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            previousLabel="<"
                            nextLabel=">"
                          />
                        ) : (
                          ""
                        )}
                      </FlexBoxPagi>
                    </Col> */}
                  </Row>
                </>
              );
            }}
          >
            <Column
              className="number"
              title="No"
              dataIndex="index"
              key="index"
              render={(index) => (
                <>
                  <span className="sm">No: </span>
                  {index}
                </>
              )}
            />
            <Column
              className="full"
              title="Create At"
              dataIndex="CreateDate"
              key="CreateDate"
              render={(CreateDate) => (
                <>
                  <span className="sm">Create at: </span>
                  {CreateDate}
                </>
              )}
            />
            <Column
              className="description"
              title="FN"
              dataIndex="Fn"
              key="Fn"
              render={(Fn) => (
                <>
                  <span className="sm">Fn: </span>
                  {Fn}
                </>
              )}
            />
            {/* <Column
              className="description"
              title="Title"
              dataIndex="Level"
              key="Level"
              render={(Level) => (
                <>
                  <span className="sm">Title: </span>
                  {Level}
                </>
              )}
            /> */}
            <Column
              className="full"
              title="Package"
              dataIndex="Level"
              key="Level"
              render={(Level) => (
                <>
                  <span className="sm">Package: </span>
                  {/* {Tier} */}
                  {Level}
                </>
              )}
            />
            <Column
              className="description"
              title="Total Invest"
              dataIndex="TotalInvest"
              key="TotalInvest"
              render={(TotalInvest) => (
                <>
                  <span className="sm">Total Invest: </span>$
                  {TotalInvest.replace("DW", "")}
                </>
              )}
            />
            <Column
              className="description"
              title="Username"
              dataIndex="Username"
              key="Username"
              render={(Username) => (
                <>
                  <span className="sm">Username: </span>
                  {`${Username?.substring(0, 4)}...${Username?.substring(
                    Username?.length - 4
                  )}`}
                </>
              )}
            />
            <Column
              className="description"
              title="StatusName"
              dataIndex="StatusName"
              key="StatusName"
              render={(StatusName) => (
                <>
                  <span className="sm">Status: </span>
                  {StatusName}
                </>
              )}
            />
          </Table>
        </TableCommon>
      </TreeTransaction>
    </TreeContainer>
  );
};

export default Tree;
