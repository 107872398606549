import * as ActionType from "../contants/AuthContanst";
import { authManagerApi } from "../service/apiAuth";
// start dashboard get
export const actListUserSuccess = (listUser: any) => ({
  type: ActionType.LIST_USER_SUCCESS,
  payload: listUser,
});

export const actListUserRequest = () => ({
  type: ActionType.LIST_USER_REQUEST,
});

export const actListUserError = (Error: any) => ({
  type: ActionType.LIST_USER_FAILED,
  error: Error,
});
export const actGetLisDasboard = () => {
  return async (dispatch: any) => {
    dispatch(actListUserRequest());
    try {
      const result = await authManagerApi.getListDashboard();
      const listUser = result.data.Data;
      dispatch(actListUserSuccess(listUser));
    } catch (error: any) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

// end dashboard get

// start history transaction

export const actLichSuGiaoDichRequest = () => ({
  type: ActionType.LIST_USER_REQUEST,
});

export const actUserLichSuGiaoDich = (InfoLichSu: any) => {
  return async (dispatch: any) => {
    dispatch(actLichSuGiaoDichRequest());

    try {
      const result = await authManagerApi.LichSuGiaoDich(InfoLichSu);
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;

      if (statusCode && statusCode === 200) {
        dispatch(actLichSuGiaoDich(globalLichSu));
      } else {
        // error
        dispatch(actLichSuGiaoDich(globalLichSu));
      }
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

export const actLichSuGiaoDich = (userActive: any) => ({
  type: ActionType.LICHSUDAUTU_SUCCESS,
  payload: userActive,
});

// end history transaction

// start Affiliates

export const actGetLisAffiliates = () => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.getListAffiliated();

      const listUser = result.data.Data.Item;
      dispatch(actListAffiliatesSuccess(listUser));
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

export const actListAffiliatesSuccess = (listAffiliates: any) => ({
  type: ActionType.LIST_AFFILIATED_SUCCESS,
  payload: listAffiliates,
});

// end Affiliates

// start Swap

export const actUserSwap = (InfoSwap: any) => {
  return async (dispatch: any) => {
    const result = await authManagerApi.SwapRequest(InfoSwap);
    const statusCode = result.data.StatusCode;
    const globalLichSu = result.data;

    if (statusCode && statusCode === 200) {
      dispatch(actSwap(globalLichSu));
    } else {
      // error
      dispatch(actSwap(globalLichSu));
    }
  };
};

export const actSwap = (userActive: any) => ({
  type: ActionType.SWAP_SUCCESS,
  payload: userActive,
});

// end Swap

// start withdraw

export const actUserWithdraw_Submit = (InfoLichSu: any) => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.WithdrawRequest_Submit(InfoLichSu);
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;
      if (statusCode && statusCode === 200) {
        dispatch(actWithdraw_Submit(globalLichSu));
      } else {
        // error
        dispatch(actWithdraw_Submit(globalLichSu));
      }
    } catch (error) {
      console.log("error");
    }
  };
};
export const actUserWithdraw_Fee = (InfoLichSu: any) => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.WithdrawRequest_Fee(InfoLichSu);
      const statusCode = result.data.StatusCode;
      const FeeObject = result.data.Data;
      if (statusCode && statusCode === 200) {
        dispatch(actWithdraw_Fee(FeeObject));
      } else {
        // error
        dispatch(actWithdraw_Fee(FeeObject));
      }
      return FeeObject
    } catch (error) {
      console.log("error");
    }
  };
};

export const getListWallet = (param: any) => {
  
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.ListWallet();
      const statusCode = result.data.StatusCode;
      const FeeObject = result.data.Data;
      return {FeeObject , statusCode};
      // if (statusCode && statusCode === 200) {
      //   dispatch(actWithdraw_Fee(FeeObject));
      // } else {
      //   // error
      //   dispatch(actWithdraw_Fee(FeeObject));
      // }
    } catch (error) {
      console.log("error");
    }
  };
};
export const actWithdraw_Fee = (userActive: any) => ({
  type: ActionType.WITHDRAW_FEE_SUCCESS,
  payload: userActive,
});
export const actWithdraw_Submit = (userActive: any) => ({
  type: ActionType.WITHDRAW_SUCCESS,
  payload: userActive,
});
// end withdraw

// start ProductSanPham

export const actUserProductSanPham = (InfoLichSu: any) => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.ProductSanPham(InfoLichSu);
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;
      const userProductSanPham = result.data;
      const resStaking = result;

      if (statusCode && statusCode === 200) {
        dispatch(actProductSanPham(userProductSanPham));
      } else {
        // Tạo lỗi
        dispatch(actProductSanPham(userProductSanPham));
      }
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

export const actProductSanPham = (userActive: any) => ({
  type: ActionType.PRODUCT_SP_SUCCESS,
  payload: userActive,
});

// end ProductSanPham

// start get Price Token

export const actGetPriceToken = () => {
  return async (dispatch: any) => {
    dispatch(actGetPriceToken_Request());
    try {
      const result = await authManagerApi.getPriceToken();
      const priceToken = result.data.Data;
      dispatch(actGetPriceToken_Success(priceToken));
    } catch (error) {
      dispatch(actGetPriceToken_Failed(error));
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

export const actGetPriceToken_Request = () => ({
  type: ActionType.LIST_PRICETOKEN_REQUEST,
});

export const actGetPriceToken_Success = (listProduct: any) => ({
  type: ActionType.LIST_PRICETOKEN_SUCCESS,
  payload: listProduct,
});

export const actGetPriceToken_Failed = (error: any) => ({
  type: ActionType.LIST_PRICETOKEN_FAILED,
  payload: error,
});

// end get Price Token

// start address-wallet get

// export const actGetAddressWallet = () => {
//   return async (dispatch: any) => {
//     dispatch(actWalletRequest());

//     try {
//       const result = await authManagerApi.getWalletAddress();

//       const listUser = result.data.Data;

//       console.log("????", listUser);

//       dispatch(actWalletSuccess(listUser));
//     } catch (error) {
//       // dispatch(actListUserFailed(error));
//       console.log("error");
//     }
//   };
// };

export const actGetAddressWallet = () => {
  return async (dispatch: any) => {
    dispatch(actWalletRequest());
    try {
      const result = await authManagerApi.getWalletAddress();

      const ReceiveAddress = result.data.Data;
      dispatch(actWalletSuccess(ReceiveAddress));
    } catch (error) {
      // dispatch(actGetPriceToken_Failed(error));
      // console.log("error");
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

// export const actWalletSuccess = () => ({
//   type: ActionType.WALLET_SUCCESS,
//   // payload: walletAddress,
// });

export const actWalletSuccess = (wallet: any) => ({
  type: ActionType.ReceiveAddress_WALLET_SUCCESS,
  payload: wallet,
});

export const actWalletRequest = () => ({
  type: ActionType.ReceiveAddress_WALLET_REQUEST,
});

// end address-wallet get

// start investment

export const actUserLichSuDauTu = (InfoLichSu: any) => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.InvestmentHistory(InfoLichSu);
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;
      if (statusCode && statusCode === 200) {
        dispatch(actLichSuDauTu(globalLichSu));
      } else {
        // eror
        dispatch(actLichSuDauTu(globalLichSu));
      }
    } catch (error) {
      // dispatch(actListAffiliatesFailed(error));
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};

export const actLichSuDauTu = (userActive: any) => ({
  type: ActionType.INVESTMENT_SUCCESS,
  payload: userActive,
});
// claim 

export const Claim = ()=>{
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.Claim();
      return result
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
}

// end investment

export const actUseRefund = (InfoRefund: any) => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.Refund(InfoRefund);
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;

      if (statusCode && statusCode === 200) {
        dispatch(actRefund(globalLichSu));
      } else {
        // error
        dispatch(actRefund(globalLichSu));
      }
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};
//get fee
export const actUseGetFee = () => {
  return async (dispatch: any) => {
    try {
      const result = await authManagerApi.GetFee();
      const statusCode = result.data.StatusCode;
      const globalLichSu = result.data;

      if (statusCode && statusCode === 200) {
        dispatch(actGetFee(globalLichSu));
      } else {
        // error
        dispatch(actGetFee(globalLichSu));
      }
    } catch (error) {
      const Error401 = error;
      dispatch(actListUserError(Error401));
    }
  };
};


export const actGetFee = (userActive: any) => ({
  type: ActionType.GET_FEE_SUCCESS,
  payload: userActive,
});

export const actRefund = (userActive: any) => ({
  type: ActionType.REFUND_SUCCESS,
  payload: userActive,
});
