import styled from "styled-components";
import arrow_right from '../../assets/common/filter/arrow-right.svg'

export const FilterContainer = styled.div`
  margin-bottom: 20px;
  .ant-select {
    max-width: 160px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px !important;
    background: #141718;
    padding: 8px 12px;
    min-height: 40px;
    color: #fcfcfd;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    border-color: transparent;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #9e77ed;
    box-shadow: none;
  }
  /* Datepicker */
  .ant-picker {
    width: 380px;
    border-radius: 12px;
    background: #141718;
    min-height: 40px;
    border-color: transparent;
    &:hover, &.ant-picker-focused {
      border-color: #9e77ed;
      box-shadow: none;
    }
    &:hover .ant-picker-suffix{
      opacity: 0;
    }
    .ant-picker-input {
      input {
        color: #fcfcfd;
        font-family: Manrope;
        font-size: 16px;
      }
    }
    .ant-picker-clear {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      background-color: transparent;
      svg path {
        fill: #fcfcfd;
      }
    }
    .ant-picker-active-bar {
      background-color: #9e77ed;
    }
    .ant-picker-range-separator {
      position: relative;
      svg {
        display: none;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        left: 0;
        background: url(${arrow_right}) no-repeat center / 100% auto;
      }
    }
  }
  @media screen and (max-width: 743px) {
    .ant-select {
      max-width: none;
      width: 160px !important;
    }
  }
`;
export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
  @media screen and (max-width: 743px) {
    flex-wrap: wrap;
    gap: 0;
  }
`;
export const ButtonFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 110px;
  width: 100%;
  min-height: 40px;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid transparent;
  background: #141718;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  color: #fcfcfd;
  @media screen and (max-width: 743px) {
    max-width: none;
    width: auto;
  }
`;
export const FilterSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 743px) {
    margin-bottom: 15px;
  }
`;
