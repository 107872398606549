const changeNetwork = async (chainId: any) => {
    // if (window.ethereum) {
    //   try {
    //     await window.ethereum.request({
    //       method: "wallet_switchEthereumChain",
    //       params: [{ chainId: chainId }],
    //     });
    //   } catch (error:any) {
    //     if (error?.code === 4902) {
    //       try {
    //         await window.ethereum.request({
    //           method: "wallet_addEthereumChain",
    //           params: [
    //             {
    //               chainId: chainId,
    //               chainName: "Binance Smart Chain",
    //               nativeCurrency: {
    //                 name: "BNB",
    //                 symbol: "bnb",
    //                 decimals: 18,
    //               },
    //               rpcUrls: [`${process.env.REACT_APP_BSC_NETWORK_URL ?? "https://bsc-dataseed.binance.org"}`],
    //               blockExplorerUrls: ["https://bscscan.com/"],
    //             },
    //           ],
    //         });
    //       } catch (addError) {
    //         console.error("Add BNB netword Error", addError);
    //       }
    //     } else {
    //       console.error("Change BNB Netword Error:", error);
    //     }
    //   }
    // } else {
    //   console.error("Metamask is underfine");
    // }
  };

export default changeNetwork