import styled from "styled-components";

import bg from "../../../assets/dashboardNew/bg.jpg";

export const DashboardContainer = styled.div`
  position: relative;
`;

export const KeyvisualMining = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-radius: var(--Parameters-Radius-2xl, 16px);
  border: 1px solid var(--Colors-Primary-Colors-Gray-400, #98a2b3);
  background: #232627;
  width: 415px;
  padding: 0 25px;
  height: 95px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  .img-icon-k {
    max-width: 55px;
  }
  .img-bg-1,
  .img-bg-2 {
    position: absolute;
    z-index: 2;
    right: 0;
    pointer-events: none;
  }
  .img-bg-1 {
    max-width: 60px;
    top: 0;
    display: none;
  }
  .img-bg-2 {
    max-width: 60px;
    bottom: -4px;
    display: none;
  }
`;

export const KeyvisualMiningRight = styled.div`
  p {
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 120% */
  }
  h1 {
    color: var(--Colors-Primary-Colors-Gray-25, #fcfcfd);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 90% */
    margin-top: 5px;
  }
`;

export const KeyvisualDashboard = styled.div`
  padding: 40px 15px;
  min-height: 458px;
  position: relative;
  background: url(${bg}) no-repeat center / 100% 100%;
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 14, 57, 0.46);
    backdrop-filter: blur(7.5px);
    border-radius: 16px;
    overflow: hidden;
  }
  @media screen and (max-width: 743px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8vw;
    margin: -4vw -5.3vw 8vw;
    border-radius: 0;
    min-height: 66.66vw;
    border-radius: 4.2vw;
    background-image: url(${bg});
    &::before {
      border-radius: 0;
    }
  }
`;
export const KeyboardLeft = styled.div<{ isflex?: any }>`
  padding-right: 20px;
  ${({ isflex }) =>
    isflex
      ? `display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  `
      : ``}
  ${KeyvisualMining} {
    width: calc(50% - 8px);
    /* min-width: 415px; */
  }
  @media only screen and (max-width: 820px) {
    ${KeyvisualMining} {
      width: calc(50% - 8px);
      min-width: 415px;
    }
  }
  @media screen and (max-width: 743px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const KeyvisualWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
`;
export const KeyvisualTitle = styled.p`
  text-transform: uppercase;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 12px;
  &.stroke {
    -webkit-text-stroke-width: 0.01px;
    -webkit-text-stroke-color: white;
    color: transparent;
  }
  @media screen and (min-width: 1081px) and (max-width: 1350px) {
    font-size: 3vw;
  }
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    font-size: 40px;
  }
  @media screen and (max-width: 743px) {
    font-size: 9.6vw;
  }
`;
export const KeyboardRight = styled.div`
  flex-shrink: 0;
  ${KeyvisualTitle} {
    text-align: right;
    font-family: PPRader;
    font-weight: 600;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    &:first-child {
      opacity: 0.7;
    }
    &:nth-child(2) {
      opacity: 0.4;
    }
    &:last-child {
      opacity: 0.2;
    }
  }
  @media screen and (max-width: 743px) {
    ${KeyvisualTitle} {
      display: none;
    }
  }
`;
export const KeyvisualText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #d0d5dd;
  max-width: 625px;
  line-height: 24px;
  margin-bottom: 20px;
  p {
    display: inline-block;
    text-decoration: underline;
    color: #fff;
    font-weight: bold;
    text-underline-offset: 2px;
  }
  @media screen and (min-width: 1081px) and (max-width: 1350px) {
    font-size: 1.3vw;
  }
  @media screen and (max-width: 743px) {
    font-size: 3.7vw;
    line-height: 5vw;
    margin-bottom: 5.3vw;
  }
`;
export const ListTitle = styled.p`
  position: relative;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  color: #fcfcfd;
  margin-bottom: 12px;
  .pop {
    position: relative;
    top: 6px;
    right: -8px;
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 743px) {
    font-size: 3.73vw;
    margin-bottom: 2.5vw;
    .pop {
      width: 5.3vw;
      height: 5.3vw;
      top: 1.6vw;
      right: -2.1vw;
    }
  }
`;
export const DashboardList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 10px;
  justify-content: center;
  margin-top: -60px;
  z-index: 10;
  position: relative;
  & > li {
    position: relative;
    width: calc(33.33% - 15px);
    min-height: 236px;
    border-radius: 16px;
    padding: 30px 16px 15px;
    background: #141718;
    overflow: hidden;
    transition: transform 0.25s, background 0.25s;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: -20px;
      right: -8px;
      width: 118px;
      height: 118px;
      filter: blur(60px);
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        transform: translateY(-10px);
        background-color: #000;
        box-shadow: 0px 4px 68px 0px rgba(145, 61, 49, 0.24);
        img {
          transform: rotate(180deg);
        }
        ${ListTitle} {
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1350px) {
    padding: 0 5px;
    gap: 10px;
    & > li {
      width: calc(33.33% - 10px);
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    gap: 10px;
    margin-top: -30px;
    & > li {
      width: calc(33.33% - 10px);
      img {
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 743px) {
    margin-top: 0;
    padding: 0;
    gap: 3.5vw;
    & > li {
      width: calc(50% - 2vw);
      min-height: 53.3vw;
      padding: 2.66vw;
      border-radius: 4.26vw;
      &::before {
        top: -4vw;
        right: -2.13vw;
        width: 26.6vw;
        height: 26.6vw;
        filter: blur(4vw);
        opacity: 0.3;
      }
      img {
        width: 38.6vw;
        height: 38.6vw;
      }
    }
  }
`;
export const ListValue = styled.p`
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Manrope;
  img {
    transform: unset !important;
    max-width: 30px;
    max-height: 30px;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
    transition: unset !important;
  }
  @media screen and (min-width: 1081px) and (max-width: 1360px) {
    font-size: 2.5vw;
  }
  @media screen and (min-width: 744px) and (max-width: 1080px) {
    font-size: 28px;
  }
  @media screen and (max-width: 743px) {
    font-size: 6vw;
  }
`;
export const ListItem = styled.li<{ color?: string }>`
  &::before {
    background: ${({ color }) => color};
  }
  ${ListValue} {
    color: ${({ color }) => color};
  }
`;

export const ItemWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  img {
    position: absolute;
    right: -60px;
    top: -80px;
    transition: transform 0.25s;
  }
  @media screen and (max-width: 743px) {
    img {
      top: -8.5vw;
      right: -8.5vw;
    }
  }
`;
