import styled from "styled-components";
import package_icon from "../../assets/dashboard/package_icon.png";
import package_icon_sp from "../../assets/dashboard/package_icon_sp.png";

export const MenuHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
`;
export const CollapseButton = styled.div<{
  collapse?: boolean;
  hover?: boolean;
}>`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 50%;
  transition: all 0.25s;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  z-index: 100;
  opacity: ${({ hover }) => (hover ? "1" : "0")};
  &.collapseIcon {
    transform: rotate(0deg);
  }
`;
export const LogoText = styled.a<{ collapse?: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fcfcfd;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  img {
    width: 55px;
    height: 55px;
  }
  span {
    transition: opacity 0.25s, font-size 0.25s;
    opacity: ${({ collapse }) => (collapse ? "0" : "1")};
    font-size: ${({ collapse }) => (collapse ? "0" : "24px")};
  }
  &:hover {
    color: #fff;
  }
  @media screen and (max-width: 743px) {
    gap: 2vw;
    font-size: 3.3vw;
    img {
      width: 9.3vw;
      height: 9.3vw;
    }
  }
`;
export const MenuPackage = styled.div<{ levelName?: string }>`
  position: relative;
  border-radius: 20px;
  background: #d6bbfb;
  width: 100%;
  min-height: ${({ levelName }) =>
    levelName !== "Not a member" ? "auto" : "268px"};
  margin-top: auto;
  padding: 145px 15px 15px;
  transition: opacity 0.1s;
  &::before {
    content: "";
    position: absolute;
    background: url(${package_icon}) no-repeat center / 100% auto;
    width: 239px;
    height: 164px;
    top: -35px;
    left: 50%;
    margin-left: -120px;
  }
  @media screen and (max-width: 743px) {
    min-height: 42.6vw;
    padding: 5.3vw;
    padding-left: 36vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    &::before {
      left: -11.73vw;
      top: -2.4vw;
      margin-left: 0;
      width: 65vw;
      height: 45vw;
      background: url(${package_icon_sp}) no-repeat center / auto 100%;
    }
  }
`;
export const PackageInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  @media screen and (max-width: 743px) {
    margin-bottom: 4vw;
    gap: 2.6vw;
  }
`;
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media screen and (max-width: 743px) {
    gap: 2vw;
    img {
      width: 9.86vw;
      height: 9.86vw;
    }
  }
`;
export const InfoUser = styled.div``;
export const InfoStatus = styled.div<{ LevelName?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  font-weight: 600;
  border: 1px solid
    ${({ LevelName }) =>
      LevelName === "Manager"
        ? "#F79009"
        : LevelName === "Platinum"
        ? "#D0D5DD"
        : LevelName === "Ruby"
        ? "#FEDF89"
        : LevelName === "Sapphire"
        ? "#E9D7FE"
        : LevelName === "Diamond"
        ? "#D1E9FF"
        : "#A6F4C5"};
  background: ${({ LevelName }) =>
    LevelName === "Manager"
      ? "#D2723C"
      : LevelName === "Platinum"
      ? "#98A2B3"
      : LevelName === "Ruby"
      ? "#FDB022"
      : LevelName === "Sapphire"
      ? "#879AFB"
      : LevelName === "Diamond"
      ? "#9EC7DD"
      : "#039855"};
  padding: 5px 15px;
  font-family: Manrope;
  font-size: 12px;
  @media screen and (max-width: 743px) {
    padding: 1.3vw 3vw;
    font-size: 2.5vw;
  }
`;
export const UserName = styled.p`
  font-family: Manrope;
  font-weight: bold;
  font-size: 18px;
  color: #101828;
  @media screen and (max-width: 743px) {
    font-size: 4vw;
  }
`;
export const UserEmail = styled.span`
  font-family: Manrope;
  color: #667085;
  font-size: 12px;
  font-weight: 600;
  @media screen and (max-width: 743px) {
    font-size: 2.5vw;
  }
`;
