import styled from "styled-components";
import keyvisualBg from "../assets/common/keyvisualBg.jpg";
import top_left from "../assets/common/box/top_left.png";
import top_right from "../assets/common/box/top_right.png";
import bottom_right from "../assets/common/box/bottom_right.png";
import bottom_left from "../assets/common/box/bottom_left.png";

export const TitleCommon = styled.h2`
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: OrbitronRegular;
  line-height: 130%;
  font-weight: 600;
  span {
    font-family: OrbitronRegular;
    background: linear-gradient(to right, #0061ff 0%, #60efff 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 919px) {
    font-size: 4.2vw;
  }
  @media only screen and (max-width: 743px) {
    font-size: 7.5vw;
  }
`;
export const ButtonCommon = styled.button<{
  transparent?: boolean;
  borderBlack?: boolean;
  isDisabled?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid
    ${({ transparent }) => (transparent ? "#706282" : " #b971f5")};
  background: ${({ transparent }) =>
    transparent
      ? "transparent"
      : " linear-gradient(270deg,rgba(167, 91, 230, 1) 0%,rgba(87, 44, 124, 1) 100%)"};
  padding: 10px 40px;
  min-height: 52px;
  width: 250px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};
  text-transform: uppercase;
  white-space: nowrap;
  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 28px;
    height: 40px;
    background: no-repeat center / 100% 100%;
  }
  &::before {
    background: ${({ borderBlack }) =>
    borderBlack
      ? 'url("../img/btn/left_black.svg") '
      : 'url("../img/btn/left.svg")'}
      no-repeat center;
    left: 8px;
    transition: left 0.3s;
  }
  &::after {
    background: ${({ borderBlack }) =>
    borderBlack
      ? 'url("../img/btn/right_black.svg") '
      : 'url("../img/btn/right.svg")'}
      no-repeat center;
    right: 8px;
    transition: right 0.3s;
  }
  &:hover {
    &::before {
      left: 12px;
    }
    &::after {
      right: 12px;
    }
  }
  @media screen and (max-width: 743px) {
    width: 37.86vw;
    min-height: 9vw;
    padding: 3.5vw;
    font-size: 3.2vw;
    &::before,
    &::after {
      content: none;
      /* width: 7.46vw;
      height: 8.5vw; */
    }
    &::before {
      left: 1vw;
    }
    &::after {
      right: 1vw;
    }
    &:hover {
      &::before {
        left: 3.2vw;
      }
      &::after {
        right: 3.2vw;
      }
    }
  }
`;
export const KeyvisualCommon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 310px;
  background: url(${keyvisualBg}) no-repeat center / cover;
  border-radius: 16px;
  h1 {
    text-transform: uppercase;
    color: #fcfcfd;
    text-align: center;
    text-shadow: 0px 0px 25px rgba(54, 111, 255, 0.75);
    font-family: Manrope;
    font-size: 64px;
    font-weight: 600;
    line-height: 96px;
  }
  @media screen and (max-width: 743px) {
    min-height: 44vw;
    margin-bottom: 6vw;
    h1 {
      font-size: 9.6vw;
    }
  }
`;
export const ButtonCloseModal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #667085;
  border-left: 1px solid #667085;
  position: absolute;
  top: 0;
  right: 0;
`;
export const BoxCommon = styled.div<{ icon?: boolean }>`
  position: relative;
  max-width: 512px;
  margin: 0 auto;
  padding: 46px 38px;
  border-radius: 24px;
  border: 1px solid #667085;
  background: rgba(29, 20, 30, 0.8);
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: 100% auto;
    width: calc(100% - 24px);
    height: 58px;
    left: 12px;
    z-index: -1;
  }
  &::before {
    top: 12px;
    background: url(${top_left}) left top no-repeat
      ${({ icon }) => (icon ? `` : `, url(${top_right}) right top no-repeat`)};
  }
  &::after {
    bottom: 12px;
    background: url(${bottom_left}) left top no-repeat,
      url(${bottom_right}) right top no-repeat;
  }
  .box-shadow {
    position: absolute;
    bottom: -82px;
    left: 50%;
    margin-left: -202px;
    width: 404px;
    height: 16px;
    border-radius: 404px;
    background: rgba(4, 1, 6, 0.5);
    filter: blur(11.5px);
  }
  @media screen and (max-width: 743px) {
    padding: 12.8vw 6vw;
    max-width: 100%;
    &::before,
    &::after {
      width: calc(100% - 6.4vw);
      height: 15.46vw;
      left: 3.2vw;
    }
    &::before {
      top: 3.2vw;
    }
    &::after {
      bottom: 3.2vw;
    }
    .box-shadow {
      bottom: -16vw;
      margin-left: -30vw;
      background-color: rgba(4, 1, 6, 0.43);
      width: 59.2vw;
      height: 7.7vw;
      filter: blur(7.5px);
    }
  }
`;
export const SelectContainer = styled.div`
  .ant-select-selector  , .itemBox {
    border: 1px solid #475467 !important;
    background: #232627 !important;
    color: #d0d5dd !important;
    height: 60px !important;
    border-radius: 12px !important;
    padding-left: 20px;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #eaecf0 !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
  @media screen and (max-width: 743px) {
    .ant-select-selector {
      height: 16vw !important;
      font-size: 4.26vw;
    }
    .ant-select-selection-item {
      .item {
        img {
          width: 8vw;
          height: 8vw;
        }
      }
    }
    .ant-select-arrow {
      img {
        width: 6.4vw;
        height: 6.4vw;
      }
    }
  }
`;
export const TableCommon = styled.div`
  border-radius: 10px;
  overflow: hidden;
  th,
  td {
    font-weight: bold !important;
    padding: 15px 10px !important;
    font-size: 16px;
  }
  .ant-table,
  .ant-table-footer {
    position: relative;
    background-color: transparent;
  }
  .ant-table-footer {
    padding-top: 35px !important;
  }
  .ant-table-thead {
    th {
      background: #141718;
      border-bottom: 1px solid #9ca3af;
      border-top: 1px solid #667085;
      color: #98a2b3;
      text-transform: uppercase;
      &::before {
        content: none !important;
      }
      &:first-child {
        text-align: center;
        border-left: 1px solid #667085;
        border-top-left-radius: 10px !important;
      }
      &:last-child {
        border-right: 1px solid #667085;
        border-top-right-radius: 10px !important;
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent !important;
  }
  .ant-table-tbody > tr > td {
    border: 0;
  }
  .ant-table-tbody {
    tr:nth-child(even) {
      background-color: #141718;
    }
    tr:nth-child(odd) {
      background-color: #232627;
    }
    tr:last-child td {
      border-bottom: 1px solid #667085;
    }
    td {
      border-color: transparent;
      color: #fcfdfe;
      font-weight: 500 !important;
      &:first-child {
        text-align: center;
        border-left: 1px solid #667085;
      }
      &:last-child {
        border-right: 1px solid #667085;
      }
    }
  }
  .ant-empty-description {
    color: #fff;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: transparent;
  }
  .pagination {
    background-color: transparent;
    .page-item {
      background-color: transparent;
      color: #fff;
      border: none;
      .page-link {
        background-color: transparent;
        color: #9ca3b0;
        border-color: #9ca3b0;
        width: 33px;
        height: 33px;
      }
      &:hover {
        border-radius: unset;
        border: 0;
      }
      &.active {
        border: none;
      }
    }
    .active>.page-link {
      border-color: #6b3796;
      background-color: #6b3796;
      color: #fff;
    }

    .page-link:focus {
      box-shadow: none;
    }
  }
  @media screen and (min-width: 744px) {
    .sm {
      display: none;
    }
  }
  @media screen and (max-width: 743px) {
    .ant-table-thead {
      display: none;
    }
    .ant-table-tbody {
      .ant-table-placeholder {
        justify-content: center;
        min-height: 100vh;
      }
      tr {
        position: relative;
        border-radius: 12px;
        &:last-child td {
          border: 0;
        }
      }
      td {
        border-bottom: 0;
        width: calc(50% - 1vw);
        text-align: left !important;
        font-size: 3.8vw;
        &:last-child {
          border-right: 0;
        }
        &:first-child {
          border-left: 0;
        }
        &:not(:last-child) {
          margin-bottom: 2.6vw;
        }
      }
      .status {
        font-size: 0;
        span {
          width: 2.6vw;
          height: 2.6vw;
          padding: 0 !important;
        }
      }
      .full {
        width: 100% !important;
      }
      /* .number,
      .status {
        position: absolute;
        top: 50%;
        width: auto;
      }
      .number {
        left: 2.2vw;
        margin-top: -3vw;
      }
      .status {
        right: 3.2vw;
        margin-top: -1.06vw;
        font-size: 0;
        span {
          width: 2.6vw;
          height: 2.6vw;
          padding: 0 !important;
        }
      }
      .amount {
        font-size: 5.3vw;
      }
      .type {
        order: 2;
      }
      .description {
        order: 0;
      }
      .createAt {
        order: 1;
      }
      .createAt,
      .type {
        color: #667085;
      }
      .amount,
      .type {
        text-align: right;
      } */
    }
    .ant-table-tbody {
      & > tr > td {
        padding: 0 !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.3vw;
        align-items: center;
        gap: 0 1.3vw;
        padding: 5.3vw 4vw;
        &:nth-child(odd) {
          background-color: #141718;
        }
      }
    }
    .ant-table-pagination-right {
      margin: 2vw 0;
    }
    .ant-pagination-item a {
      padding: 1vw;
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 8.5vw;
      height: 8.5vw;
      font-size: 4.26vw;
    }
    .ant-table-footer {
      padding-top: 16vw !important;
      padding-bottom: 2.6vw;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 1300px) {
    .ant-table-content {
      overflow-x: auto;
      padding-bottom: 10px;
    }
    .ant-table table {
      width: 100vw;
    }
  }
`;
