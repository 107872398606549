import { useState } from "react";
import { NetworkBody, NetworkContainer } from "./styled";
import Tree from "./Tree";
import Binary from "./Binary";
import TabsCommon from "../../../components/tabs";

const Network = () => {
  const [tabs, setTabs] = useState<string>("1");
  const handleChangeTabs = (key: string) => {
    setTabs(key);
  };

  return (
    <NetworkContainer>
      <NetworkBody>
        <TabsCommon
          activeKey={tabs}
          dataTabs={dataTabs}
          onChange={handleChangeTabs}
        />
        {/* {tabs === "1" ? <Tree /> : <Binary />} */}
        {<Tree />}
      </NetworkBody>
    </NetworkContainer>
  );
};

const dataTabs = [
  {
    key: "1",
    label: "Affiliate",
    component: "",
  },
  // {
  //   key: "2",
  //   label: "Binary",
  //   component: "",
  // },
];

export default Network;
