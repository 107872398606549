import styled from "styled-components";

import lineBefore from "../../assets/common/box/lineBefore.svg";

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 743px) {
    padding: 0 6.25vw;
  }
`;

export const BoxLayout = styled.div`
  position: relative;
  width: 420px;
  min-height: 367px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 16px rgba(1, 16, 51, 0.75);
  backdrop-filter: blur(8px);

  border: 1.5px solid;
  border-image-source: linear-gradient(
    144.12deg,
    #004eff 10.79%,
    #19010d 93.15%
  );
  border-radius: 16px;
  margin-bottom: 23px;
  @media only screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const BoxSwapLayout = styled.div`
  position: relative;
  width: 100%;
  padding: 18px 24px 24px;
  div {
    :first-child {
      p {
        :first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 14px;
            line-height: 20px;
            font-family: ClashDisplayMedium;
            :first-child {
              font-weight: 400;
            }
            :last-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 743px) {
    padding: 20px 10px;
    .change-icon {
      width: 22.5vw;
      height: 22.5vw;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
`;

export const BoxSelect = styled.div`
  position: relative;
  width: 100%;
`;

export const BoxInput = styled.input`
  border-radius: 8px;
  height: 60px;
  outline: none;
  width: 100%;
  position: relative;
  padding-right: 36%;
  border-radius: 12px;
  border: 1px solid #475467;
  background: #232627;
  color: #d0d5dd;
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;
  transition: border-color 0.25s;
  &#button-disabled {
    padding-right: 28%;
  }
  &:hover,
  &:focus {
    border-color: #eaecf0;
    background: #232627;
  }
  @media screen and (max-width: 743px) {
    height: 16vw;
    padding-right: 38vw;
    font-size: 4.26vw;
    padding-left: 5.3vw;
    &:focus {
      font-size: 4.26vw;
    }
    &#button-disabled {
      padding-right: 28vw;
    }
  }
`;
export const BtnMax = styled.button`
  background-color: transparent;
  border: none;
  &:focus{
    transform: translateY(3%);
  }
  p {
    position: relative;
    padding-right: 27px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
    color: #fcfcfd;
    transition: color 0.25s;
    cursor: pointer;
    &:hover {
      color: #e0eaef;
    }
  }
`
export const BoxIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  transition: all .2s ease;
  &:active{
    transform: translateY(-40%);
  }
  p {
    position: relative;
    padding-right: 27px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
    color: #fcfcfd;
    transition: color 0.25s;
    cursor: pointer;
    &:hover {
      color: #e0eaef;
    }
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  & > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -15px;
      width: 9px;
      height: 60px;
      margin-top: -30px;
      background: url(${lineBefore}) no-repeat center / 100% auto;
    }
  }
  @media screen and (max-width: 743px) {
    right: 20px;
    p {
      padding-right: 6vw;
      font-size: 4.26vw;
    }
    img {
      width: 8vw;
      height: 8vw;
    }
    & > div {
      gap: 2.6vw;
      font-size: 4.26vw;
      &::before {
        left: -3vw;
        width: 2.73vw;
        height: 12vw;
        margin-top: -6vw;
      }
    }
  }
`;

export const Des = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  P {
    font-size: 18px;
  }
  @media only screen and (max-width: 743px) {
    font-size: 5vw;
    p {
      font-size: 5.6vw;
    }
  }
`;

export const AutoRenew = styled.div`
  @media only screen and (max-width: 743px) {
    svg {
      width: 6.5vw;
      height: 5.6vw;
    }
  }
`;

export const ButtonSwap = styled.button`
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #004eff 0%, #043199 101.16%);
  border: 1.5px solid #032980;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background: linear-gradient(89.92deg, #004eff 0.06%, #0542cc 99.93%);
    box-shadow: 0px 0px 20px rgba(0, 78, 255, 0.5);
  }
  @media only screen and (max-width: 743px) {
    height: 15vw;
    font-size: 5.6vw;
  }
`;

export const BoxTotalLayout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
