import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "@raca2022/uikit";
import { ArrowRightModalIcon } from "../Svg";

export default function WalletItem(props: any) {
  return (
    <div
      className={`flex items-center ${props.className}`}
      onClick={props.onClick}
      onMouseLeave={() => props.setHover(-1)}
      onMouseEnter={() => props.setHover(props.index)}
    >
      <div className="wallet-flex">
        <div>
          <Box className="wallet-icon">{props.icon}</Box>
          <p onClick={(e) => e.preventDefault()}>{props.title}</p>
          <span>{props.describe}</span>
        </div>
      </div>
    </div>
  );
}
