import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useModal } from "@raca2022/uikit";
import { ConnectWallet } from "../components/Connect";
import { useAccount, useChainId, useBalance } from "wagmi";

const usePresentRiskDisclaimerRef = () => {
  const location = useLocation();
  const [onModalCustomMust] = useModal(<ConnectWallet />, false);
  const { address } = useAccount();

  const isConnectVeri = window.localStorage.getItem("accountStatus");

  const isOkPathname = (location: any, address: any) => {
    return location.pathname !== "/" && isConnectVeri !== "1";
  };

  const onPresentRiskDisclaimerRef = useRef(onModalCustomMust);

  useEffect(() => {
    if (isOkPathname(location, address)) {
      onPresentRiskDisclaimerRef.current();
    }
  }, [location, address, onPresentRiskDisclaimerRef]);
};

export default usePresentRiskDisclaimerRef;
