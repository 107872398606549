import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Router } from "react-router-dom";
import Providers from "./Provider";
import reportWebVitals from "./reportWebVitals";
import App from "./view";

import "./index.css";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import { LoginProvider } from "./components/Provider/LoginProvider";
import Loading from "./components/Loading/LoadingDesktop";
import history from "./routerHistory";
import { ModalConfirmProvider } from "./components/ProviderPopUp/confirm";

ReactDOM.render(
  <Providers>
    <BrowserRouter>
      <LoginProvider>
        <Suspense fallback={<Loading/>}>
          <Router history={history}>
            <ModalConfirmProvider>
              <App />
            </ModalConfirmProvider>
          </Router>
        </Suspense>
      </LoginProvider>
    </BrowserRouter>
  </Providers>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//
